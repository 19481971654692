import { useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import { ClientSmsConfirmation } from '~widgets/client-sms-confirmation';

import { CreateApplicationError } from '~features/create-application-result';

import {
  applicationsKeys,
  upgradeApplicationIdAtom,
} from '~entities/application';
import { useApplicationById } from '~entities/application';
import { type FindOrCreateApplicationError } from '~entities/auth';

import { useOnMountEffect } from '~shared/hooks';
import { FormHeader } from '~shared/ui/form-header';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

import Text from '@breeze-platform-ui/text';
import { useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export const ClientConfirmationPage = () => {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const upgradeApplicationId = useSetAtom(upgradeApplicationIdAtom);

  const redirectUrl = searchParams.get('redirectUrl') || '';

  const applicationId = (redirectUrl as string).split('/')[2];

  const {
    data: application,
    isSuccess,
    isError,
  } = useApplicationById(applicationId);
  useOnMountEffect(() => {
    // Clear cache to request actual data after redirect
    queryClient.resetQueries({
      queryKey: applicationsKeys.activeApplication(),
    });
  });

  const [confirmationError, setConfirmationError] = useState<{
    error: FindOrCreateApplicationError;
  } | null>(null);

  const handleSuccess = () => {
    upgradeApplicationId(applicationId);
    navigate(`${redirectUrl}`, { state });
  };

  const getContent = () => {
    if (confirmationError) {
      return (
        <CreateApplicationError
          error={confirmationError.error}
          application={application}
          onClose={() => navigate(`/applications/${applicationId}`)}
        />
      );
    }

    if (isSuccess) {
      return (
        <ClientSmsConfirmation
          phoneNumber={application.person.mobilePhone}
          onSuccess={handleSuccess}
          onError={(error) => {
            setConfirmationError({
              error: error as FindOrCreateApplicationError,
            });
          }}
        />
      );
    }
    if (isError) {
      return <Text>Error getting customer confirmation</Text>;
    }
    return <Loader />;
  };

  return (
    <Screen
      header={
        <FormHeader
          href={`/applications/${applicationId}`}
          text="Enter the&nbsp;code"
        />
      }
    >
      {getContent()}
    </Screen>
  );
};
