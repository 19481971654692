import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { GiftPaymentSettings } from '../gift-payments-api';

export const getGiftPayment = (params?: HandlerParams<GiftPaymentSettings[]>) =>
  http.post(
    '*/origination/api/v2/applications/pos-loan/gift-payment/settings/get',
    async () => {
      return HttpResponse.json(
        mockSuccessResponse<GiftPaymentSettings[]>(params?.payload ?? [])
      );
    }
  );

export const getGiftPaymentError = http.post(
  '*/origination/api/v2/applications/pos-loan/gift-payment/settings/get',
  async () => {
    return HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    });
  }
);
