import {
  useMemo,
  useRef,
  useReducer,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { environment } from '~app/environment';
import {
  useAnalytics,
  useCustomerAnalytics,
} from '~app/providers/analytics-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { ClientAssessment } from '~widgets/client-assessment';
import { ClientPhoto } from '~widgets/client-photo';
import { AdditionalIdDocument } from '~widgets/loan-application-form/additional-id-document';
import {
  EphilVerificationForm,
  EphilVerificationLink,
} from '~widgets/loan-application-form/e-phil-verification';
import { GCashDetails } from '~widgets/loan-application-form/gcash-details';
import { IdDocument } from '~widgets/loan-application-form/id-document';
import { IdDocumentType } from '~widgets/loan-application-form/id-document-type/ui/id-document-type';

import {
  type ApplicationLoanDetails,
  useUpdateApplication,
  useInitialApplicationUpdate,
  applicationsKeys,
} from '~entities/application';
import { getDeviceMetadata } from '~entities/device-metadata';
import { type Person, useUpdatePerson } from '~entities/person';
import { selectedPosAtom } from '~entities/pos';

import { mergeFio } from '~shared/lib/merge-fio';
import { StepFlowStrategy } from '~shared/lib/step-flow-strategy';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Loader } from '~shared/ui/loader';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Step, Stepper } from '~shared/ui/stepper';

import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import type {
  ApplicationStepName,
  PersonDetailsStepName,
  FormValues,
  StepName,
  FormErrors,
} from '../../lib';
import { applicationStepNames, featureFlags } from '../../lib';
import {
  stepNames,
  formatApplicationScreenValues,
  formatPersonScreenValues,
  personDetailsStepNames,
} from '../../lib';
import {
  formStateReducer,
  formStateActions,
  StepFlowMachine,
} from '../../model';

const initialStep = stepNames.idDocumentType;

type Props = {
  applicationId: string;
  clientPhone: string;
  initialValues: Partial<FormValues>;
  applicationLoanDetails: ApplicationLoanDetails;
  personDetails: Partial<Person>;
  promoterCode?: string;
  onSubmit: () => void;
};

export const SelfApplicationAgentForm = ({
  applicationId,
  initialValues,
  promoterCode,
  applicationLoanDetails,
  personDetails,
  onSubmit,
}: Props) => {
  const analytics = useAnalytics();
  const tracking = useTracking();
  const customerAnalytics = useCustomerAnalytics();
  const isFirstUpdate = useRef(true);
  const backWasUsed = useRef(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const posId = useAtomValue(selectedPosAtom)?.id;

  useEffect(() => {
    customerAnalytics.trackShortFormFillingStarted({ applicationId });
  }, [customerAnalytics, applicationId]);

  const { mutate: updatePerson } = useUpdatePerson();
  const {
    mutate: updateApplication,
    finalMutateAsync: submitApplicationAsync,
  } = useUpdateApplication();
  const { mutate: initialUpdateApplication } = useInitialApplicationUpdate();
  const [isFinalSubmitLoading, setIsFinalSubmitLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<StepName>(initialStep);
  const [formState, dispatch] = useReducer(formStateReducer, {
    values: initialValues,
    errors: {},
  });

  const stepFlowStrategy = useMemo(
    () => new StepFlowStrategy<StepName>(StepFlowMachine(initialStep)),
    []
  );

  const goNext = (currentStepValues: FormValues[StepName]) => {
    const nextStep = stepFlowStrategy.next({
      ...formState.values,
      [currentStep]: currentStepValues,
    });
    setCurrentStep(nextStep);
  };

  const goBack = () => {
    const prevScreenName = stepFlowStrategy.prev();
    setCurrentStep(prevScreenName);
    backWasUsed.current = true;
  };

  const buildApplication = useCallback(
    (
      currentStepValues: Partial<FormValues[ApplicationStepName]>,
      currentStepErrors: Partial<FormErrors[ApplicationStepName]>
    ) => {
      const formValues = {
        ...formState.values,
        [currentStep]: currentStepValues,
      };
      const formErrors = {
        ...formState.errors,
        [currentStep]: currentStepErrors,
      };
      const futureSteps = stepFlowStrategy
        .getFullFlow(formValues)
        .filter(
          (step) => step in applicationStepNames
        ) as ApplicationStepName[];

      const formattedApplication = formatApplicationScreenValues(
        futureSteps,
        formValues,
        formErrors
      );
      return {
        ...formattedApplication,
        ...applicationLoanDetails,
        posId,
        mobileAppInstalled: true,
        metaData: {
          promoterCode,
          appVersion: environment.VERSION,
          ...getDeviceMetadata(tracking?.state),
          ...(formattedApplication?.metaData ?? {}),
        },
      };
    },
    [
      applicationLoanDetails,
      formState,
      promoterCode,
      stepFlowStrategy,
      tracking,
      currentStep,
      posId,
    ]
  );

  const buildPerson = (
    currentStepValues: Partial<FormValues[PersonDetailsStepName]>,
    currentStepErrors: Partial<FormErrors[PersonDetailsStepName]>
  ) => {
    const formValues = {
      ...formState.values,
      [currentStep]: currentStepValues,
    };
    const formErrors = {
      ...formState.errors,
      [currentStep]: currentStepErrors,
    };

    const futureSteps = stepFlowStrategy
      .getFullFlow(formValues)
      .filter(
        (step) => step in personDetailsStepNames
      ) as PersonDetailsStepName[];

    const person = formatPersonScreenValues(
      futureSteps,
      formValues,
      formErrors
    );
    return {
      ...personDetails,
      ...person,
      name: { ...personDetails.name, ...person.name },
      metaData: {
        ...person.metaData,
        appVersion: environment.VERSION,
        gCashScore: person.metaData?.gCashScore ?? null,
      },
    };
  };

  const handleFormChange = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: Partial<FormErrors[StepName]>
  ) => {
    if (isFinalSubmitLoading) {
      return;
    }

    const isPersonDetailsStep = currentStep in personDetailsStepNames;

    if (isPersonDetailsStep) {
      updatePerson(
        buildPerson(
          currentStepValues as Partial<FormValues[PersonDetailsStepName]>,
          currentStepErrors as Partial<FormErrors[PersonDetailsStepName]>
        )
      );
    }

    if (!isPersonDetailsStep) {
      updateApplication(
        buildApplication(
          currentStepValues as Partial<FormValues[ApplicationStepName]>,
          currentStepErrors as Partial<FormErrors[ApplicationStepName]>
        )
      );
    }
  };

  const handleFormStateUpdate = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: Partial<FormErrors[StepName]>
  ) => {
    dispatch(
      formStateActions.update({
        step: currentStep,
        value: currentStepValues,
        errors: currentStepErrors,
      })
    );
  };

  const handleGoBack = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: FormErrors[StepName]
  ) => {
    handleFormStateUpdate(currentStepValues, currentStepErrors);
    goBack();
  };

  const handleStepSubmit = (currentStepValues: FormValues[StepName]) => {
    handleFormStateUpdate(currentStepValues, {});
    handleFormChange(currentStepValues, {});
    goNext(currentStepValues);
  };

  const handleFinalSubmit = async (
    currentStepValues: FormValues[StepName],
    skipServerValidation?: boolean
  ) => {
    if (isFinalSubmitLoading) {
      return;
    }
    setIsFinalSubmitLoading(true);
    handleFormStateUpdate(currentStepValues, {});
    if (backWasUsed.current) {
      analytics.trackFormBackClick();
      customerAnalytics.trackFormBackClick({ applicationId });
    }
    try {
      await submitApplicationAsync({
        ...buildApplication(
          currentStepValues as Partial<FormValues[ApplicationStepName]>,
          {}
        ),
        ...(skipServerValidation ? featureFlags : {}),
        formFillingStatus: 'FINISHED',
      });
      queryClient.resetQueries({
        queryKey: applicationsKeys.activeApplication(),
      });
      queryClient.resetQueries({
        queryKey: applicationsKeys.application(applicationId),
      });
      queryClient.resetQueries({
        queryKey: applicationsKeys.listByPos(posId),
      });
      onSubmit();
    } catch {
      setIsFinalSubmitLoading(false);
    }
  };

  const handleClose = () => {
    if (backWasUsed.current) {
      analytics.trackFormBackClick();
      customerAnalytics.trackFormBackClick({ applicationId });
    }
    navigate('/');
  };

  const { idDocument } = formState.values;

  const name = useMemo(
    () =>
      mergeFio(
        idDocument?.firstName,
        idDocument?.lastName,
        idDocument?.middleName
      ),
    [idDocument]
  );

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      initialUpdateApplication(buildApplication({}, {}));
    }
  }, [initialUpdateApplication, buildApplication]);

  const { idDocumentType } = formState.values;
  const idType = idDocumentType?.typeMain ?? idDocumentType?.typeOther;

  return (
    <>
      <Stepper current={currentStep}>
        <Step name={stepNames.idDocumentType}>
          <ScrollIntoView>
            <IdDocumentType
              name={name}
              initialValue={formState.values[stepNames.idDocumentType]}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
              onClose={handleClose}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.idDocument}>
          <ScrollIntoView>
            <IdDocument
              name={name}
              applicationId={applicationId}
              currentIdType={nonNullableValue(idType)}
              initialValue={formState.values[stepNames.idDocument]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
              onClose={handleClose}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.ephilVerificationLink}>
          <ScrollIntoView>
            <EphilVerificationLink
              applicationId={applicationId}
              isVerified={
                !!formState.values[stepNames.ephilVerificationForm]
                  ?.ephilIdVerified
              }
              name={name}
              onVerify={() => goNext({})}
              onPrev={goBack}
              onClose={handleClose}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.ephilVerificationForm}>
          <ScrollIntoView>
            <EphilVerificationForm
              initialValue={formState.values[stepNames.ephilVerificationForm]}
              name={name}
              onPrev={goBack}
              onSubmit={handleStepSubmit}
              onClose={handleClose}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.additionalIdDocument}>
          <ScrollIntoView>
            <AdditionalIdDocument
              name={name}
              initialValue={formState.values[stepNames.additionalIdDocument]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
              onClose={handleClose}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.clientPhoto}>
          <ScrollIntoView>
            <ClientPhoto
              name={name}
              applicationId={applicationId}
              initialValue={formState.values[stepNames.clientPhoto]}
              onPrev={handleGoBack}
              onSubmit={handleStepSubmit}
              onClose={handleClose}
            />
          </ScrollIntoView>
        </Step>

        <Step name={stepNames.gCashDetails}>
          <ScrollIntoView>
            <GCashDetails
              name={name}
              applicationId={applicationId}
              initialValue={formState.values[stepNames.gCashDetails]}
              onPrev={handleGoBack}
              onSubmit={handleStepSubmit}
              onClose={handleClose}
            />
          </ScrollIntoView>
        </Step>

        <Step name={stepNames.clientAssessment}>
          <ScrollIntoView>
            <ClientAssessment
              name={name}
              initialValue={formState.values[stepNames.clientAssessment]}
              isSelfApp
              onPrev={handleGoBack}
              onSubmit={handleFinalSubmit}
              onClose={handleClose}
            />
          </ScrollIntoView>
        </Step>
      </Stepper>
      {isFinalSubmitLoading && <Loader overlay semiTransparentBg />}
    </>
  );
};
