import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type { ClientInitiationNavigationState } from '~pages/client-initiation';

import { FormLoadingPage } from '~widgets/form-loading-page';
import { formatLoanCalculator } from '~widgets/loan-calculator';

import { ActiveApplicationWarning } from '~features/create-application-result';

import type { ApplicationLoanDetails } from '~entities/application';
import { useApplication } from '~entities/application';
import { usePerson } from '~entities/person';

import {
  useFormRenderTime,
  useApplicationPageNavigationConstraints,
} from '~shared/hooks';

import Text from '@breeze-platform-ui/text';

import { SelfApplicationAgentForm } from './form/self-application-agent-form';
import { HasPreApprovedPopup } from './has-pre-approved-popup/has-pre-approved-popup';

import {
  recoverApplicationScreenValues,
  recoverPersonScreenValues,
} from '../lib';

export const SelfApplicationAgentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loanCalculator, promoterCode, clientInitiation } =
    (location.state as ClientInitiationNavigationState) || {};
  const analytics = useCustomerAnalytics();
  const { isLoading: isApplicationLoading, data: applicationData } =
    useApplication();
  const { isLoading: isPersonLoading, data: personData } = usePerson();
  const [isHasPreApprovedPopupOpend, setHasPreApprovedPopupOpened] =
    useState(!!clientInitiation);
  const isFormLoading = isApplicationLoading || isPersonLoading;

  useApplicationPageNavigationConstraints();
  useFormRenderTime(isFormLoading);

  if (isFormLoading) {
    return <FormLoadingPage />;
  }

  if (applicationData) {
    if (
      applicationData.applicationStatus !== 'NEW' ||
      applicationData.formFillingStatus === 'FINISHED'
    ) {
      return <ActiveApplicationWarning applicationId={applicationData.id} />;
    }

    const recoveredApplication =
      recoverApplicationScreenValues(applicationData);

    let applicationLoanDetails: ApplicationLoanDetails = {
      orderItems: applicationData.orderItems,
      requestedProduct: {
        type: 'POS_LOAN',
        ...applicationData.requestedProduct,
      },
      offer: applicationData.offer,
      promoCode: applicationData.promoCode,
    };

    if (loanCalculator) {
      applicationLoanDetails = formatLoanCalculator(loanCalculator);
    }

    return (
      <>
        <SelfApplicationAgentForm
          clientPhone={applicationData.person.mobilePhone}
          applicationLoanDetails={applicationLoanDetails}
          personDetails={personData || {}}
          applicationId={applicationData.id}
          initialValues={{
            ...recoverPersonScreenValues(personData || {}),
            ...recoveredApplication,
          }}
          promoterCode={promoterCode || applicationData.metaData?.promoterCode}
          onSubmit={() => {
            analytics.trackShortFormSubmitted({
              applicationId: applicationData.id,
            });
            navigate(`/applications/${applicationData.id}`, { replace: true });
          }}
        />
        <HasPreApprovedPopup
          isOpened={isHasPreApprovedPopupOpend}
          onClose={() => setHasPreApprovedPopupOpened(false)}
        />
      </>
    );
  }
  return <Text>Application creation error</Text>;
};
