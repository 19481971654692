import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  activeApplicationIdAtom,
  applicationsKeys,
  useApplication,
} from '~entities/application';
import { selectedPosAtom } from '~entities/pos';

import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const ActiveApplicationGuardHOC = ({
  children,
  onAccessDenied,
}: React.PropsWithChildren<{ onAccessDenied: () => void }>) => {
  const queryClient = useQueryClient();
  const activeApplicationId = useAtomValue(activeApplicationIdAtom);
  const currentApplicationId = useParams().id;

  const activeApplication = useApplication();
  const selectedPos = useAtomValue(selectedPosAtom);
  const currentApplicationPosId = activeApplication.data?.posId;

  /**
   * if the agent has changed current pos
   * or upgraded the token in the other tab
   * we should reset active applications
   */
  const tokenWasUpgraded =
    currentApplicationId && activeApplicationId !== currentApplicationId;
  const posWasChanged =
    currentApplicationPosId &&
    selectedPos &&
    currentApplicationPosId !== selectedPos?.id;
  useEffect(() => {
    if (tokenWasUpgraded || posWasChanged) {
      queryClient.resetQueries({
        queryKey: applicationsKeys.activeApplication(),
      });
      onAccessDenied();
    }
  }, [tokenWasUpgraded, posWasChanged, queryClient, onAccessDenied]);

  return <>{children}</>;
};
