import { Enabled, useQuery } from '@tanstack/react-query';

import { agentKeys } from './agent-keys';
import { gatewayAgentApi } from './gateway-agent-api';

import type { AgentInfo } from '../agent';

export function useAgentInfo<TSelect = AgentInfo>(options?: {
  enabled?: Enabled<AgentInfo, Error, AgentInfo>;
  select?: (info: AgentInfo) => TSelect;
}) {
  return useQuery({
    queryKey: agentKeys.info(),
    queryFn: async () => gatewayAgentApi.getAgentInfo(),
    ...options,
  });
}
