import { useAnalytics } from '~app/providers/analytics-provider';
import { useDi } from '~app/providers/di-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { applicationsKeys } from '~entities/application';
import { getDeviceMetadata } from '~entities/device-metadata';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { isSuccessResponse } from '../api';
import type { UpgradeInitiateParams, InitiateParams } from '../api';

export const useInitiate = () => {
  const { authApi } = useDi();
  const tracking = useTracking();
  return useMutation({
    mutationFn: (params: Omit<InitiateParams, 'userMetadata'>) =>
      authApi.initiate({
        ...params,
        userMetadata: {
          webFingerprint: getDeviceMetadata(tracking?.state),
        },
      }),
  });
};

export const useUpgradeInitiate = () => {
  const queryClient = useQueryClient();
  const analytics = useAnalytics();
  const { authApi } = useDi();
  const tracking = useTracking();

  return useMutation({
    mutationFn: async (params: Omit<UpgradeInitiateParams, 'userMetadata'>) =>
      authApi.upgradeInitiate({
        ...params,
        userMetadata: {
          webFingerprint: getDeviceMetadata(tracking?.state),
        },
      }),
    onSuccess: async (response) => {
      if (isSuccessResponse(response)) {
        queryClient.resetQueries({
          queryKey: applicationsKeys.activeApplication(),
        });

        try {
          const { clientAnalyticsPersonId } = await authApi.getUserInfo();
          if (clientAnalyticsPersonId) {
            analytics.setClientAnalyticsId(clientAnalyticsPersonId);
          }

          // eslint-disable-next-line no-empty
        } catch (_err) {}
      }
    },
  });
};
