import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type { ClientInitiationNavigationState } from '~pages/client-initiation';

import { formatLoanCalculator } from '~widgets/loan-calculator';

import {
  useApplicationById,
  useInitialApplicationUpdate,
} from '~entities/application';
import type { ApplicationLoanDetails } from '~entities/application';
import { isOnboarding } from '~entities/application';
import { selectedPosAtom } from '~entities/pos';

import { useOnMountEffect } from '~shared/hooks';
import { BasicHeader } from '~shared/ui/basic-header';
import { NavCloseButton } from '~shared/ui/nav-close-button';
import { Screen } from '~shared/ui/screen';

import { LoadingButton } from '@breeze-platform-ui/button';
import { Col, Divider } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { useAtomValue } from 'jotai';

import styles from './mobile-self-app-redirect.module.css';

import qrSvg from '../images/qr.svg';

export const MobileSelfAppRedirectPage = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const analytics = useCustomerAnalytics();
  const posId = useAtomValue(selectedPosAtom)?.id;

  const applicationQuery = useApplicationById(id);
  const { mutate: updateApplication } = useInitialApplicationUpdate();
  const clientInitiationState = useMemo(
    () => (state as ClientInitiationNavigationState) || {},
    [state]
  );
  const wasUpdatedWithLoanDataRef = useRef(false);

  useOnMountEffect(() => {
    if (id) {
      analytics.trackContinueInMobileAppShown({ applicationId: id });
    }
  });

  useEffect(() => {
    if (!applicationQuery.data || wasUpdatedWithLoanDataRef.current) {
      return;
    }

    const { loanCalculator, promoterCode } = clientInitiationState;

    if (loanCalculator && isOnboarding(applicationQuery.data)) {
      const applicationLoanDetails: ApplicationLoanDetails =
        formatLoanCalculator(loanCalculator);
      updateApplication(
        {
          posId,
          ...applicationLoanDetails,
          promoCode: promoterCode,
        },
        { onSuccess: () => (wasUpdatedWithLoanDataRef.current = true) }
      );
    }
  }, [applicationQuery.data, clientInitiationState, posId, updateApplication]);

  return (
    <Screen
      header={
        <BasicHeader actionButton={<NavCloseButton href="/applications" />} />
      }
      footer={
        <Col width="100%" alignCross="center">
          <LoadingButton
            wide
            onClick={() => navigate('/applications')}
            loading={applicationQuery.isLoading}
          >
            Done
          </LoadingButton>
          <Divider color="transparent" margin={8} />
          <Text color="#9299A2">Customers can’t apply themselves?</Text>
          <Link
            to={`/loan-application/${id}`}
            onClick={() =>
              analytics.trackSwitchToAgentFlow({ applicationId: id as string })
            }
            state={state}
            className={styles.link}
          >
            Continue here
          </Link>
        </Col>
      }
    >
      <h1 className={styles.title}>
        Scan the&nbsp;QR code to download the&nbsp;Skyro app
      </h1>
      <img src={qrSvg} className={styles.qr} alt="Mobile app QR" />
    </Screen>
  );
};
