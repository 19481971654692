import { Enabled, useQuery } from '@tanstack/react-query';

import { gatewayScheduleApi } from './gateway-schedule-api';
import { scheduleKeys } from './schedule-keys';

import { DailySchedule } from '../schedule';

export function useDailySchedule(
  date: string,
  options?: {
    enabled: Enabled<DailySchedule | null, Error, DailySchedule | null>;
  }
) {
  return useQuery({
    queryKey: scheduleKeys.byDate(date),
    queryFn: async () => {
      const response = await gatewayScheduleApi.getDailySchedule({ date });

      return response.schedule ?? null;
    },
    ...options,
  });
}
