import { useEffect, useRef, useState } from 'react';

import { logger } from '~entities/logger';

import { PopupCloseButton } from '~shared/ui/popup-close-button';

import throttle from '@tinkoff/utils/function/throttle';

import Clickable from '@breeze-platform-ui/clickable';
import Text from '@breeze-platform-ui/text';
import { useNotifications } from '@pfa/front-notifications';
import QrScanner from 'qr-scanner';

import styles from './pre-approved-qr-reader.module.css';

type Props = {
  onClose: () => void;
  onSuccess: (values: { applicationId: string; phoneNumber: string }) => void;
  onCanNotScanQr: () => void;
};

export const PreApprovedQrReader = ({
  onClose,
  onSuccess,
  onCanNotScanQr,
}: Props) => {
  const notifications = useNotifications();

  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  const [isNotRecognizedError, setNotRecognizedError] = useState(false);

  useEffect(() => {
    const throttleLogError = throttle(5000, (error: Error) =>
      logger.error(error)
    );
    const onScanSuccess = (result: QrScanner.ScanResult) => {
      try {
        const content = JSON.parse(result.data);
        const { applicationId, phoneNumber } = content;
        if (
          typeof applicationId === 'string' ||
          typeof phoneNumber === 'string'
        ) {
          setTimeout(() => {
            onSuccess({ applicationId, phoneNumber });
          }, 500);
        } else {
          setNotRecognizedError(true);
          throttleLogError(
            new Error(
              `Scanned QR doesn't have required fields. Content: ${result.data}`
            )
          );
        }
      } catch (_error) {
        setNotRecognizedError(true);
        throttleLogError(
          new Error(`Scanned QR can not be parsed. Content: ${result.data}`)
        );
      }
    };

    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }
    return () => {
      scanner?.current?.stop();
    };
  }, [onSuccess]);

  useEffect(() => {
    if (!qrOn) {
      alert(
        'Camera is blocked or not accessible. Please allow camera in your browser permissions and reload.'
      );
    }
  }, [qrOn, notifications]);

  return (
    <div className={styles.qrReader}>
      <div className={styles.closeButton}>
        <PopupCloseButton onClick={onClose} />
      </div>
      <div className={styles.message}>Scan customer’s QR code</div>
      <video muted className={styles.video} ref={videoEl} />
      <div ref={qrBoxEl} className={styles.qrBox}>
        <div className={styles.qrFrame} />
      </div>
      {isNotRecognizedError && (
        <div className={styles.notRecognizedError}>
          <Text color="white" bold size="15_small">
            The QR code is not recognized
          </Text>
        </div>
      )}
      <Clickable onClick={onCanNotScanQr}>
        <div className={styles.canNotScanQr}>Can’t scan the&nbsp;QR code</div>
      </Clickable>
    </div>
  );
};
