import { useState, useEffect, useRef } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type {
  MainIdDocumentTypeValue,
  OtherIdDocumentTypeValue,
} from '~widgets/loan-application-form/id-document-type/lib/id-document-type-options';

import {
  useUploadFileMutation,
  useGetFullOcrMutation,
  isOcrSuccessResponse,
  isOcrErrorResponse,
  type OcrResult,
} from '~entities/file';
import { idDocumentTypeNames } from '~entities/person';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Col } from '@breeze-platform-ui/layout';
import type { FileItem } from '@breeze/rhf-adapters';
import {
  AttachFile,
  InputCapitalize,
  InputDate,
  MaskedInput,
  FILE_STATUS,
} from '@breeze/rhf-adapters';
import { useNotifications } from '@pfa/front-notifications';

import {
  TAKE_PHOTO_FIELD_NAME,
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  MIDDLE_NAME_FIELD_NAME,
  BIRTHDATE_FIELD_NAME,
  DATE_OF_BIRTH_REQUIRED,
  REQUIRED_NUMBER_ERROR,
} from '../constants';
import {
  type OcrPrefillingFields,
  idDocumentMetaMap,
  ocrToFormMapping,
} from '../lib';
import type { FormValues } from '../types';

type Props = {
  applicationId: string;
  name: string;
  currentIdType: MainIdDocumentTypeValue | OtherIdDocumentTypeValue;
  onSubmit: (formValues: FormValues) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
  initialValue?: Partial<FormValues>;
};

export const IdDocument = ({
  applicationId,
  name,
  currentIdType,
  onSubmit,
  initialValue,
  onPrev,
  onFieldCompleted,
  onClose,
}: Props) => {
  const { mutateAsync } = useUploadFileMutation();
  const { mutateAsync: ocrMutate } = useGetFullOcrMutation();
  const analytics = useCustomerAnalytics();
  const notifications = useNotifications();
  const [ocrResult, setOcrResult] = useState<OcrResult>();
  const ocrFormData = useRef<Partial<FormValues>>({});
  const methods = useForm<FormValues>({
    // we need to reset id document data if document type has been changed
    defaultValues:
      initialValue?.idType === currentIdType
        ? initialValue
        : {
            ...initialValue,
            idType: currentIdType,
            idNumber: '',
            idPhoto: [],
          },
    mode: 'all',
  });
  const { watch, control, getValues, setValue, handleSubmit, register } =
    methods;

  const idPhoto = watch('idPhoto');

  const image = Array.isArray(idPhoto) ? (idPhoto[0] as FileItem) : idPhoto;

  useRevalidate(methods);

  const idDocumentMeta = idDocumentMetaMap[currentIdType];

  useEffect(() => {
    if (ocrResult) {
      analytics.trackOcrSuccess({ applicationId });
      (Object.keys(ocrToFormMapping) as OcrPrefillingFields[]).forEach(
        (ocrField) => {
          const ocrValue = ocrResult[ocrField];
          if (ocrValue) {
            const { field, format } = ocrToFormMapping[ocrField];
            const value = format(ocrValue);
            ocrFormData.current = { ...ocrFormData.current, [field]: value };
            setValue(field, value, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
          }
        }
      );
    }
  }, [ocrResult, setValue, analytics, applicationId]);

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const { idPhoto: _idPhoto, ...documentDetails } = getValues();
    const actualErrors = getFieldsErrors<FormValues>(methods);
    onFieldCompleted?.(documentDetails as Partial<FormValues>, actualErrors);
  };

  const handleFormSubmit = (value: FormValues) => {
    if (image && image.status === FILE_STATUS.LOADING) {
      notifications.warning('The file is still loading', {
        showClose: true,
        timer: 3_000,
      });
      return;
    }

    const analyticsPayload: { [name: string]: boolean } = {};
    // If some of ocr recognized fields were changed - send ocr_result_modified event
    // the recognized field in payload is true if it was modified, false otherwise
    (Object.keys(ocrFormData.current) as (keyof FormValues)[]).forEach(
      (field) => {
        analyticsPayload[`${field}_changed`] =
          ocrFormData.current[field] !== value[field];
      }
    );
    if (Object.values(analyticsPayload).some((v) => v)) {
      analytics.trackOcrResultModified({ applicationId, ...analyticsPayload });
    }

    onSubmit(value);
  };

  const handleIdPhotoUpload = async (file: FileItem) => {
    const res = await mutateAsync({
      applicationId,
      fileItem: {
        docType: currentIdType,
        frontImage: file.file,
      },
    });
    let ocrRes;
    try {
      ocrRes = await ocrMutate(res.transactionId);
    } catch (_err) {
      analytics.trackOcrError({ applicationId });
      // Do not notify the user if ocr is failed, just show fields for manual filling
    }
    if (ocrRes) {
      if (isOcrSuccessResponse(ocrRes)) {
        setOcrResult(ocrRes.OCRresult);
      }
      if (isOcrErrorResponse(ocrRes)) {
        analytics.trackOcrError({
          applicationId,
          status: ocrRes.status,
          code: ocrRes.errorCode,
        });
      }
    }
    return res;
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(handleFormSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <Col gaps={20} alignCross="stretch">
          <ScreenTitle
            title={`Take a photo of ${
              idDocumentTypeNames[getValues('idType')]
            }`}
            subtitle={
              <>
                The&nbsp;photo must be clear and the&nbsp;text on it must be
                readable. Avoid cropping parts of the&nbsp;document
              </>
            }
          />

          <input {...register('idType')} hidden />
          <Controller
            control={control}
            name="idPhoto"
            shouldUnregister
            rules={{
              validate: validators.fileRequired({
                message: 'Please, take a photo of the document',
              }),
            }}
            render={(fieldProps) => (
              <AttachFile
                {...fieldProps}
                uploadFile={handleIdPhotoUpload}
                preview
                single
                accept="image/*"
                capture="environment"
                labels={{
                  common: TAKE_PHOTO_FIELD_NAME,
                  dragging: ' ',
                  desktop: ' ',
                }}
              />
            )}
          />
          {image?.status === FILE_STATUS.SUCCESS && (
            <>
              <SectionTitle
                title={<>Make sure we recognized the&nbsp;document correctly</>}
              />
              <Controller
                control={control}
                name="idNumber"
                shouldUnregister
                rules={{
                  required: REQUIRED_NUMBER_ERROR,
                  ...idDocumentMeta.validators,
                }}
                render={(fieldProps) => (
                  <MaskedInput
                    {...fieldProps}
                    field={{
                      ...fieldProps.field,
                      value: fieldProps.field.value || '',
                      onChange: (event, params) => {
                        fieldProps.field.onChange(
                          idDocumentMeta.transform(event, params)
                        );
                      },
                    }}
                    partialPlaceholder
                    label={idDocumentMeta.label}
                    mask={idDocumentMeta.mask.value}
                    maskTransitions={idDocumentMeta.mask.transitions}
                    placeholder={idDocumentMeta.placeholder}
                  />
                )}
              />
              <Controller
                name="firstName"
                control={control}
                rules={{
                  validate: combineValidators(
                    validators.required({
                      text: 'Enter customer’s first name',
                    }),
                    validators.maxLength({ maxLength: 300 }),
                    validators.filipinoName()
                  ),
                }}
                render={(fieldProps) => (
                  <InputCapitalize
                    label={FIRST_NAME_FIELD_NAME}
                    {...fieldProps}
                  />
                )}
              />
              <Controller
                name="middleName"
                control={control}
                rules={{
                  validate: combineValidators(
                    validators.maxLength({ maxLength: 300 }),
                    validators.filipinoName()
                  ),
                }}
                render={(fieldProps) => (
                  <InputCapitalize
                    label={MIDDLE_NAME_FIELD_NAME}
                    {...fieldProps}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{
                  validate: combineValidators(
                    validators.required({ text: 'Enter customer’s last name' }),
                    validators.maxLength({ maxLength: 300 }),
                    validators.filipinoName()
                  ),
                }}
                render={(fieldProps) => (
                  <InputCapitalize
                    label={LAST_NAME_FIELD_NAME}
                    {...fieldProps}
                  />
                )}
              />
              <Controller
                name="birthDate"
                control={control}
                rules={{
                  required: DATE_OF_BIRTH_REQUIRED,
                  validate: combineValidators(
                    validators.isDate(),
                    validators.ageRange({
                      minAge: 0,
                      maxAge: 130,
                    })
                  ),
                }}
                render={(fieldProps) => (
                  <InputDate
                    label={BIRTHDATE_FIELD_NAME}
                    {...fieldProps}
                    field={{
                      ...fieldProps.field,
                      value: fieldProps.field.value || '',
                      onChange: (_event, params) => {
                        fieldProps.field.onChange(params.maskedValue);
                      },
                    }}
                  />
                )}
              />
            </>
          )}
        </Col>
      </Screen>
    </form>
  );
};
