import { ApplicationDetails } from '~entities/application';

import { mockSuccessResponse } from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

type Scenario = 'Finished' | 'Cancelled' | 'Active' | 'FormFilled';

const getFinishedApplicationList = (reqPostId: string) => {
  switch (reqPostId) {
    case '01GGFJ77ASA8891AN33AT7161H': {
      // The first Breeze POS
      return [
        {
          id: '78ab7568-d0ea-422b-b645-6d49b29e40b8',
          employeeId: 'b0b2914f-b72e-4815-ac04-f54956cb7579',
          posId: '01GGFJ77ASA8891AN33AT7161H',
          merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
          offerSigned: false,
          downPaymentVerified: false,
          verificationCompleted: false,
          mobileAppInstalled: false,
          metaData: null,
          person: {
            fullName: 'Dmitriy Dekhanov',
            mobilePhone: '+639231701555',
          },
          orderItems: [
            {
              name: 'Cell phone',
              price: '100000.00',
              quantity: 1,
              category: 'Cell phones and accessories',
            },
          ],
          requestedProduct: {
            type: 'POS_LOAN',
            desiredLoanAmount: '100000.00',
            desiredLoanTerm: 6,
            downPayment: 20000,
          },
          approvedProduct: null,
          formFillingStatus: 'FINISHED',
          applicationStatus: 'IN_PROGRESS',
          applicationCancelled: false,
          applicationStatusChangedUTC: '2022-11-05T19:42:08.985503Z',
          createdUTC: '2022-11-05T19:36:13.946174Z',
          lastUpdatedUTC: '2022-11-05T19:42:08.982093Z',
        },
        {
          id: 'c8ce7836-849a-44a8-b7fe-66de840f11fe',
          employeeId: 'b0b2914f-b72e-4815-ac04-f54956cb7579',
          posId: '01GGFJ77ASA8891AN33AT7161H',
          merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
          offerSigned: false,
          downPaymentVerified: false,
          verificationCompleted: true,
          mobileAppInstalled: true,
          metaData: null,
          person: {
            fullName: 'Evgeniy Vladimirovich Filkin',
            mobilePhone: '+639012312344',
          },
          orderItems: [
            {
              name: 'Phone',
              price: '43212.00',
              quantity: 1,
              category: 'Cell phones and accessories',
            },
          ],
          requestedProduct: {
            type: 'POS_LOAN',
            desiredLoanAmount: '43212.00',
            desiredLoanTerm: 6,
            downPayment: 21606,
          },
          approvedProduct: null,
          formFillingStatus: 'FINISHED',
          applicationStatus: 'REJECTED',
          applicationCancelled: false,
          applicationStatusChangedUTC: '2022-11-07T06:13:41.249503Z',
          createdUTC: '2022-11-07T06:07:15.241391Z',
          lastUpdatedUTC: '2022-11-07T06:13:41.247088Z',
        },
      ];
    }
    case '01GGPY3RKJ9CVD0D7GR7VH1HK1': {
      // Apple Store
      return [
        {
          id: 'd7a08961-1d4c-48cd-ab08-8fbba31aecdd',
          employeeId: 'b0b2914f-b72e-4815-ac04-f54956cb7579',
          posId: '01GGPY3RKJ9CVD0D7GR7VH1HK1',
          merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
          offerSigned: false,
          downPaymentVerified: false,
          verificationCompleted: false,
          mobileAppInstalled: false,
          metaData: null,
          person: {
            fullName: 'An Anan An',
            mobilePhone: '+639992392392',
          },
          orderItems: [
            {
              name: 'Galaxy S22',
              price: '1999.00',
              quantity: 1,
              category: 'Cell phones and accessories',
            },
          ],
          requestedProduct: {
            type: 'POS_LOAN',
            desiredLoanAmount: '1999.00',
            desiredLoanTerm: 6,
            downPayment: 400,
            productId: '01GM5FTC6SKH1QAW9VX5MPJDM6',
            campaignId: 'TEST_CAMPAIGN_ID',
          },
          approvedProduct: null,
          formFillingStatus: 'FINISHED',
          applicationStatus: 'IN_PROGRESS',
          applicationCancelled: false,
          applicationStatusChangedUTC: '2022-11-09T03:32:45.068512Z',
          createdUTC: '2022-11-09T03:24:36.033971Z',
          lastUpdatedUTC: '2022-11-09T03:32:45.062142Z',
        },
      ];
    }
    default: {
      return [];
    }
  }
};

export const getApplicationList = (
  params?: HandlerParams<ApplicationDetails[], Scenario>
) =>
  http.post(
    '*/origination/api/v2/applications/pos-loan/get-list',
    async ({ request }) => {
      if (params?.scenario === 'Finished') {
        const req = (await request.json()) as Record<'posId', string>;

        return HttpResponse.json(
          mockSuccessResponse(getFinishedApplicationList(req?.posId))
        );
      }

      return HttpResponse.json(mockSuccessResponse([]));
    }
  );
