import { GetProductQueryParams } from './get-product';

export const productKeys = {
  all: () => ['products'] as const,
  byParams: (params: GetProductQueryParams) =>
    [...productKeys.all(), params] as const,
  categories: () => ['items-categories'] as const,
  zeroRateItems: (posId: string) => ['zero-rate-items', posId],
  selectedZeroRateItems: (posId: string, selectedIds: string[]) => [
    ...productKeys.zeroRateItems(posId),
    ...selectedIds,
  ],
};
