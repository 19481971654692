import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { DailySchedule, WorkDayMode } from '../../schedule';

export const getDailySchedule = (params?: HandlerParams<DailySchedule>) =>
  http.post('*/wfm/api/v2/web/schedule/get-by-day', async () => {
    return HttpResponse.json(
      mockSuccessResponse(
        params?.payload ?? {
          schedule: {
            type: 'WORK',
            posList: [
              {
                id: '01GH8FJNHGJQ4718ZT5GPT0J6Y',
                name: '0% Interest Loan + Loan Default Product',
              },
              {
                id: '01GGFJ77ASA8891AN33AT7161H',
                name: '0% Interest Loan + POS Loan',
              },
            ],
            mode: WorkDayMode.FULL_DAY,
            shiftTime: {
              startAt: '2024-10-06T19:00:00Z',
              endAt: '2024-10-07T18:45:00Z',
            },
          } as DailySchedule,
        }
      )
    );
  });

export const getDailyScheduleError = http.post(
  '*/wfm/api/v2/web/schedule/get-by-day',
  async () => {
    return HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    });
  }
);
