import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type { PreCalcNavigationState } from '~pages/loan-calculator';

import { recoverLoanCalculator } from '~widgets/loan-calculator';
import { PreApprovedQrReader } from '~widgets/pre-approved-qr-reader';

import { SelfAppPreApprovedIncorrect } from '~features/create-application-result';

import {
  useApplicationById,
  isActiveApplication,
  isPreApproved,
} from '~entities/application';
import { useSearchOfferByPhone } from '~entities/offer';
import { selectedPosAtom } from '~entities/pos';

import Button from '@breeze-platform-ui/button';
import Loader from '@breeze-platform-ui/loader';
import { useNotifications } from '@pfa/front-notifications';
import { useAtomValue } from 'jotai';

import { HasQRPopup } from './has-qr-popup';
import styles from './start-loan-calculation.module.css';

import { OfferNotFoundError } from '../offer-not-found-error/offer-not-found-error';

type PreapprovedApplicationData = {
  applicationId: string;
  phoneNumber: string;
};

export const StartLoanCalculation = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const analytics = useCustomerAnalytics();
  const [hasQrSheetOpened, setHasQrSheetOpened] = useState(false);
  const [qrReaderShown, setQrReaderShown] = useState(false);
  const [preApprovedApplication, setPreApprovedApplication] =
    useState<PreapprovedApplicationData>();
  const [selfAppError, setSelfAppError] = useState<boolean>();
  const [isSearchOfferError, setIsSearchOfferError] = useState<boolean>();
  const [isSearchingOffer, setIsSearchingOffer] = useState(false);
  const selectedPos = useAtomValue(selectedPosAtom);

  const { mutateAsync: searchOffer } = useSearchOfferByPhone();
  const handleSearchOffer = useCallback(
    async (mobilePhone: string) => {
      setIsSearchingOffer(true);
      try {
        const offer = await searchOffer(mobilePhone);
        if (offer?.status === 'SUCCESS') {
          return offer;
        }
      } catch (_err) {
        return undefined;
      } finally {
        setIsSearchingOffer(false);
      }
    },
    [setIsSearchingOffer, searchOffer]
  );
  const { isFetching: isApplicationFetching } = useApplicationById(
    preApprovedApplication?.applicationId,
    {
      enabled: !!selectedPos,
    },
    {
      async onSuccess(application) {
        const { requestedProduct, posId } = application;
        if (isPreApproved(application)) {
          const state: PreCalcNavigationState = { preApprovedApplication };
          if (requestedProduct && posId === selectedPos?.id) {
            state.loanCalculator = recoverLoanCalculator(application);
          }

          const offer = await handleSearchOffer(application.person.mobilePhone);

          if (offer) {
            state.forcedOffer = {
              offer,
              phone: application.person.mobilePhone,
            };
          }
          navigate('/loan-calculator', { state });
        }
        if (isActiveApplication(application) && selectedPos?.id === posId) {
          return navigate(`/client-initiation`, {
            state: { clientPhonePrefilled: application.person.mobilePhone },
          });
        }
        setSelfAppError(true);
      },
      onError() {
        notifications.error('Failed to fetch application details');
      },
    }
  );

  useEffect(() => {
    if (preApprovedApplication?.applicationId) {
      analytics.trackPreApprovedQrScanned({
        applicationId: preApprovedApplication.applicationId,
      });
    }
  }, [preApprovedApplication?.applicationId, analytics]);

  const handleNoQr = () => {
    navigate('/loan-calculator');
  };

  const handleYesClick = () => {
    setHasQrSheetOpened(false);
    setQrReaderShown(true);
  };

  const handleOfferScannedSuccess = useCallback(
    async (phone: string) => {
      const offer = await handleSearchOffer(phone);
      const state: PreCalcNavigationState = {};

      if (offer) {
        state.forcedOffer = {
          offer,
          phone,
        };
        return navigate('/loan-calculator', { state });
      }

      setIsSearchOfferError(true);
    },
    [navigate, handleSearchOffer]
  );

  const handleScanSuccess = useCallback(
    (values: Partial<PreapprovedApplicationData>) => {
      const preApprovedApplicationFound = values.applicationId;
      if (preApprovedApplicationFound) {
        setPreApprovedApplication(values as PreapprovedApplicationData);
      } else if (values.phoneNumber) {
        handleOfferScannedSuccess(values.phoneNumber);
      }
      setQrReaderShown(false);
    },
    [handleOfferScannedSuccess]
  );

  const handleScanClose = useCallback(() => {
    setQrReaderShown(false);
  }, []);

  const isCheckingPreApprovedApplication =
    isApplicationFetching || isSearchingOffer;

  return (
    <>
      <Button
        type="button"
        size="l"
        wide
        onClick={() => setHasQrSheetOpened(true)}
      >
        Calculate a&nbsp;new loan
      </Button>
      <HasQRPopup
        isOpened={hasQrSheetOpened}
        onClose={() => setHasQrSheetOpened(false)}
        onNoClick={handleNoQr}
        onYesClick={handleYesClick}
      />
      {qrReaderShown && (
        <PreApprovedQrReader
          onClose={handleScanClose}
          onSuccess={handleScanSuccess}
          onCanNotScanQr={handleNoQr}
        />
      )}
      {isCheckingPreApprovedApplication && (
        <div className={styles.container}>
          <Loader centered overlay />
        </div>
      )}
      {selfAppError && (
        <div className={styles.container}>
          <SelfAppPreApprovedIncorrect
            onClose={() => {
              setSelfAppError(false);
              setPreApprovedApplication(undefined);
            }}
          />
        </div>
      )}
      {isSearchOfferError && (
        <div className={styles.container}>
          <OfferNotFoundError
            onClose={() => {
              setIsSearchOfferError(false);
              setPreApprovedApplication(undefined);
            }}
          />
        </div>
      )}
    </>
  );
};
