import {
  ErrorDetails,
  ResponseError,
  ResponseSuccess,
} from '@breezeventures/scp-client';
import { faker } from '@faker-js/faker';

function mockTraceID(): string {
  return faker.string.alpha(10);
}

function mockProcessingTime(): string {
  return faker.number.int({ min: 100, max: 2000 }).toString();
}

export function mockSuccessResponse<P>(payload: P): ResponseSuccess<P> {
  return {
    result: 'OK',
    traceID: mockTraceID(),
    processingTime: mockProcessingTime(),
    payload,
  };
}

export function mockErrorResponse(error: ErrorDetails): ResponseError {
  return {
    result: 'ERROR',
    traceID: mockTraceID(),
    processingTime: mockProcessingTime(),
    error,
  };
}

export const mockedErrorResponses = {
  // 500
  INTERNAL_SERVER_ERROR: mockErrorResponse({
    code: 'INTERNAL_SERVER_ERROR',
    message: 'Internal server error',
  }),
  // 401
  UNAUTHORIZED: mockErrorResponse({
    code: 'UNAUTHORIZED',
    message: 'Unauthorized',
  }),
  // 403
  FORBIDDEN: mockErrorResponse({
    code: 'FORBIDDEN',
    message: 'Forbidden',
  }),
} as const satisfies Record<string, ResponseError>;
