import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { AttendanceStatus } from '../../attendance';
import { AttendanceStatusResponse } from '../attendance-api';

export const getAttendanceStatus = (
  params?: HandlerParams<AttendanceStatusResponse>
) =>
  http.post('*/attendance/api/v1/attendance/status', async () => {
    return HttpResponse.json(
      mockSuccessResponse<AttendanceStatusResponse>(
        params?.payload ?? {
          status: AttendanceStatus.CheckOut,
        }
      )
    );
  });

export const getAttendanceStatusError = http.post(
  '*/attendance/api/v1/attendance/status',
  async () => {
    return HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    });
  }
);
