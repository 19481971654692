import errorImg from '~shared/images/error.svg';
import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';

type Props = { onClose: () => void };

export const OfferNotFoundError = ({ onClose }: Props) => (
  <Warning
    icon={errorImg}
    title="Couldn’t find any special offers"
    description="Check your QR-code or continue without offer"
    footerContent={
      <div className="flex w-full flex-col items-center gap-4">
        <Button size="m" wide onClick={onClose}>
          Ok
        </Button>
      </div>
    }
  />
);
