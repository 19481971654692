import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { ZeroRateItem } from '../../product';

export const getZeroRateItems = (params?: HandlerParams<ZeroRateItem[]>) =>
  http.post(
    '*/merchant-portal/api/v1/public/products/zero-rate/get-items',
    async () => {
      return HttpResponse.json(
        mockSuccessResponse<ZeroRateItem[]>(
          params?.payload ?? [
            {
              id: '01H3H76KNEBT3G1488ZAQ1172M',
              name: 'iPhone 11',
              categoryCode: 'MOBILES_AND_TABLETS',
              manufacturerIdType: 'IMEI',
              eligibleForMultiChoice: true,
              availableForSelection: true,
            },
          ]
        )
      );
    }
  );

export const getZeroRateItemsError = http.post(
  '*/merchant-portal/api/v1/public/products/zero-rate/get-items',
  async () =>
    HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    })
);
