import { atomWithBroadcast } from '~shared/atoms/atom-with-broadcast';

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import type { Pos } from '../pos';

const posAtom = atomWithStorage<Pos | null>(
  'merchant_portal_selected_pos',
  null,
  undefined,
  { getOnInit: true }
);

export const selectedPosAtom = atomWithBroadcast<Pos | null>({
  key: 'merchant_portal_selected_pos',
  sourceAtom: posAtom,
});

export const isPosInitializedAtom = atom(false);
