import { SetStateAction } from 'react';

import { atom, WritableAtom } from 'jotai';

type Props<
  Value = null,
  Atom extends WritableAtom<
    Value,
    [SetStateAction<Value>],
    void
  > = WritableAtom<Value, [SetStateAction<Value>], void>,
> = {
  key: string;
  initialValue?: Value;
  sourceAtom?: Atom;
};

function broadcast<
  Value,
  Atom extends WritableAtom<
    Value,
    [SetStateAction<Value>],
    void
  > = WritableAtom<Value, [SetStateAction<Value>], void>,
>(props: Props<Value, Atom>) {
  const baseAtom = props.sourceAtom
    ? props.sourceAtom
    : atom<Value>(props.initialValue as Value);

  const listeners = new Set<(event: MessageEvent<any>) => void>();
  const channel = new BroadcastChannel(props.key);
  channel.onmessage = (event) => {
    listeners.forEach((l) => l(event));
  };

  const broadcastAtom = atom<Value, [{ isEvent: boolean; value: Value }], void>(
    (get) => get(baseAtom),
    (get, set, update) => {
      set(baseAtom, update.value);

      if (!update.isEvent) {
        channel.postMessage(get(baseAtom));
      }
    }
  );
  broadcastAtom.onMount = (setAtom) => {
    const listener = (event: MessageEvent<any>) => {
      setAtom({ isEvent: true, value: event.data });
    };
    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  };
  const returnedAtom = atom<Value, [Value], void>(
    (get) => get(broadcastAtom),
    (_get, set, update) => {
      set(broadcastAtom, { isEvent: false, value: update });
    }
  );
  return returnedAtom;
}

export const atomWithBroadcast =
  typeof window === 'undefined' ? atom : broadcast;
