import { useDi } from '~app/providers/di-provider';

import { selectedPosAtom } from '~entities/pos';

import { useQueryStatusHandlers } from '~shared/react-query-helpers/hooks/use-query-status-handlers';
import { RequestError } from '~shared/scp-client';
import { nonNullableValue } from '~shared/types/non-nullable-value';

import { Enabled, useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { ProductRequestParams } from './product-api';
import { productKeys } from './product-keys';

import { isProductValidationError, ProductValidationError } from '../model';
import { OrderParams, Product } from '../product';

export type GetProductQueryParams = Pick<
  ProductRequestParams,
  'orderItems' | 'productType'
> & {
  offerProductId?: string;
};
type GetProductResponse = Product | OrderParams;

export const useProduct = (
  params: GetProductQueryParams,
  options?: {
    enabled: Enabled<GetProductResponse, Error>;
  },
  resultHandlers?: {
    onSettled?: (
      data: GetProductResponse | undefined,
      error: Error | null
    ) => void;
  }
) => {
  const { productApi } = useDi();
  const selectedPos = useAtomValue(selectedPosAtom);

  const queryResult = useQuery({
    queryKey: productKeys.byParams(params),
    queryFn: async () => {
      const selectedPOS = nonNullableValue(selectedPos);

      try {
        return await productApi.getProduct({
          channel: 'OFFLINE',
          pointOfSaleId: selectedPOS.id,
          merchantId: selectedPOS.merchantId,
          ...params,
        });
      } catch (error) {
        if (isProductValidationError(error as RequestError)) {
          const validationParams = (error as ProductValidationError).body;

          return {
            maxOrderTotalAmount: validationParams.maxOrderTotalAmount,
            minOrderTotalAmount: validationParams.minOrderTotalAmount,
          };
        }

        throw error;
      }
    },
    ...options,
  });
  useQueryStatusHandlers({
    queryResult,
    ...resultHandlers,
  });
  return queryResult;
};
