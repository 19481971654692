import { useDi } from '~app/providers/di-provider';

import omit from '@tinkoff/utils/object/omit';

import { useQuery } from '@tanstack/react-query';

import {
  SuggestionField,
  ExtendedAddressSuggestionOption,
  SuggestionQueryParams,
  SuggestionsResult,
} from './address-suggestions-api';
import { addressSuggestionsKeys } from './adress-suggestions-keys';

import { Address } from '../address';
import { getChildFields } from '../lib';

export const useSuggestionsOptions = ({
  params,
  name,
}: SuggestionQueryParams) => {
  const { addressSuggestionApi } = useDi();

  return useQuery({
    queryKey: addressSuggestionsKeys.byName({ params, name }),
    queryFn: async (): Promise<SuggestionsResult> => {
      const addressFields: SuggestionField[] = [
        'province',
        'cityOrMunicipality',
        'barangay',
      ];
      const childFields = getChildFields(
        name,
        addressFields
      ) as SuggestionField[];

      let suggestions: Address[] = [];
      let shouldResetChildFields = false;

      const requestParams = {
        resultSize: 100,
        ...params,
      };
      try {
        suggestions =
          await addressSuggestionApi.getAddressSuggestion(requestParams);

        if (suggestions.length === 0 && childFields.length > 0) {
          suggestions = await addressSuggestionApi.getAddressSuggestion(
            omit(childFields, requestParams)
          );
          shouldResetChildFields = true;
        }
      } catch (_error) {
        // do nothing
      }
      const suggestionOptions = suggestions.reduce<
        ExtendedAddressSuggestionOption[]
      >((acc, suggestion: Address) => {
        const text = suggestion[name];
        if (text) {
          acc.push({ title: text, value: text, origin: suggestion });
        }
        return acc;
      }, []);

      return { options: suggestionOptions, shouldResetChildFields };
    },
  });
};
