import { useMutation, useQueryClient } from '@tanstack/react-query';

import type {
  AttachAttendanceFilePayload,
  AttendanceLogPayload,
  AttendanceStatusResponse,
  SendExplanatoryNotePayload,
} from './attendance-api';
import { attendanceKeys } from './attendance-keys';
import { gatewayAttendanceApi } from './gateway-attendance-api';

export function useLogAttendance() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: AttendanceLogPayload) =>
      gatewayAttendanceApi.logAttendance(payload),
    onSuccess: async (_data, variables) => {
      await queryClient.setQueryData<Partial<AttendanceStatusResponse>>(
        attendanceKeys.status(),
        {
          status: variables.type,
          posId: variables.posId,
        }
      );
    },
    onError: (error) => {
      queryClient.invalidateQueries({ queryKey: attendanceKeys.status() });
      throw error;
    },
  });
}

export function useAttachAttendancePhoto() {
  return useMutation({
    mutationFn: async (payload: AttachAttendanceFilePayload) => {
      try {
        await gatewayAttendanceApi.uploadPhoto(payload);
        // eslint-disable-next-line no-empty
      } catch (_err) {}

      return 'OK';
    },
  });
}

export function useSendExplanatoryNote() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: SendExplanatoryNotePayload) =>
      gatewayAttendanceApi.sendExplanatoryNote(payload),
    onSuccess: () => {
      const statusKey = attendanceKeys.status();
      queryClient.setQueryData<Partial<AttendanceStatusResponse>>(statusKey, {
        ...queryClient.getQueryData<AttendanceStatusResponse>(statusKey),
        explanatoryNoteExists: true,
      });
    },
  });
}
