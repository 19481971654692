import { useDi } from '~app/providers/di-provider';

import { useQuery } from '@tanstack/react-query';

import { productKeys } from './product-keys';

export const useItemsCategories = () => {
  const { productApi } = useDi();

  return useQuery({
    queryKey: productKeys.categories(),
    queryFn: () => productApi.getCategories(),
  });
};
