import { FormEvent, useState } from 'react';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';

type Props = {
  name: string;
  onVerify: () => void;
  onPrev: () => void;
  onClose: () => void;
  applicationId: string;
  isVerified: boolean;
};

export const EphilVerificationLink = ({
  name,
  onClose,
  onPrev,
  onVerify,
  applicationId,
  isVerified,
}: Props) => {
  const [isClicked, setIsClicked] = useState(isVerified);
  const [showError, setShowError] = useState(false);
  const analytics = useCustomerAnalytics();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isClicked) {
      setShowError(true);
      return;
    }
    onVerify();
  };

  const handleVerifyClick = () => {
    onVerify();
    setIsClicked(true);
    analytics.trackVerifyEphilIdClicked({
      applicationId,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={onPrev} />}
      >
        <Col gaps={20} alignCross="stretch">
          <ScreenTitle
            title="Verify ePhil&nbsp;ID on&nbsp;the&nbsp;PhilSys website"
            subtitle="This step is&nbsp;required. To&nbsp;verify, scan the&nbsp;QR code at&nbsp;the&nbsp;back of&nbsp;the&nbsp;ePhil&nbsp;ID"
          />
          <div>
            <Button
              wide
              size="l"
              theme="outline"
              href="https://verify.philsys.gov.ph/"
              target="_blank"
              onClick={handleVerifyClick}
            >
              Verify ePhil ID
            </Button>
            {showError && (
              <div className="mt-1 text-body-s text-text-negative">
                Verify the&nbsp;ID before proceeding
              </div>
            )}
          </div>
        </Col>
      </Screen>
    </form>
  );
};
