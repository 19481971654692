import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { Pos } from '../../pos';

export const posListPayload = [
  // POS without zero rate items
  {
    id: '01GGPY3RKJ9CVD0D7GR7VH1HK1',
    name: 'Apple Store',
    fullAddress:
      'RG4, Craig st, 1008, Caloocan, Sampaloc, Manila, Metro Manila, PHL',
    merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
    merchantName: 'PSJC POS-test',
    companyId: 'a9ebe7fe-7c9a-4182-94ae-ab464a0bcd8f',
    hasZeroRateProducts: false,
  },
  // POS with zero rate items
  {
    id: '01GGWK689AC1BS1J58XG0X0R51',
    name: 'Experimental point',
    fullAddress:
      'RG4, Craig st, 1008, Caloocan, Sampaloc, Manila, Metro Manila, Philippines',
    merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
    merchantName: 'PSJC POS-test',
    companyId: 'a9ebe7fe-7c9a-4182-94ae-ab464a0bcd8f',
    status: 'ACTIVE',
    hasZeroRateProducts: true,
  },
  // POS with zero rate items and gift cashback
  {
    id: '01GGWK689AC1BS1J58XG0X0R58',
    name: 'Experimental point with gift cahshback',
    fullAddress:
      'RG4, Craig st, 1008, Caloocan, Sampaloc, Manila, Metro Manila, Philippines',
    merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
    merchantName: 'PSJC POS-test',
    companyId: 'a9ebe7fe-7c9a-4182-94ae-ab464a0bcd8f',
    status: 'ACTIVE',
    hasZeroRateProducts: true,
    hasZeroRateGiftCashbackProducts: true,
  },
  {
    id: '01GGPY3RKJ9CVD0D7GR7VH1HK1',
    name: 'Loan DP 20% + Loan DP 0%',
    fullAddress:
      '1, 1, 1, || DP 0% with categories HOME_APPLIANCES and TV_AND_ELECTRONICS, — Use different categories to get product with DP 20% , Manila, Metro Manila, Philippines',
    merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
    merchantName: 'PSJC POS-test',
    companyId: 'a9ebe7fe-7c9a-4182-94ae-ab464a0bcd8f',
    status: 'ACTIVE',
    hasZeroRateProducts: false,
  },
  {
    id: '01H9HYB00SRMC70FY493TD1K0X',
    name: 'Point with zero Down payment',
    fullAddress:
      'RG4, Craig st, 1008, Caloocan, Sampaloc, Manila, Metro Manila, Philippines',
    merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
    merchantName: 'PSJC POS-test',
    companyId: 'a9ebe7fe-7c9a-4182-94ae-ab464a0bcd8f',
    status: 'ACTIVE',
    hasZeroRateProducts: false,
  },
  {
    id: '01GM5MV0TCXH300XSMM5RC1V86',
    name: 'Point without applications',
    fullAddress:
      'RG4, Craig st, 1008, Caloocan, Sampaloc, Manila, Metro Manila, PHL',
    merchantId: '01GGENJCCZY27F1M9HD8JS0ZEW',
    merchantName: 'PSJC POS-test',
    companyId: 'a9ebe7fe-7c9a-4182-94ae-ab464a0bcd8f',
    status: 'ACTIVE',
    hasZeroRateProducts: false,
  },
];

export const getPosList = (params?: HandlerParams<Pos[]>) =>
  http.post(
    '*/merchant-portal/api/v1/public/point-of-sales/find-all-by-employee-id',
    async () => {
      return HttpResponse.json(
        mockSuccessResponse<Pos[]>(params?.payload ?? posListPayload)
      );
    }
  );

export const getPosListError = http.post(
  '*/merchant-portal/api/v1/public/point-of-sales/find-all-by-employee-id',
  async () => {
    return HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    });
  }
);
