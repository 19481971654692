import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';
import { useCalculatorAnalytics } from '~app/providers/analytics-provider';

import { type PreCalcNavigationState } from '~pages/loan-calculator';

import { ClientPhone } from '~widgets/client-phone';
import { ClientSmsConfirmation } from '~widgets/client-sms-confirmation';

import { AuthorizationError } from '~features/auth/otp';
import { setOtpRequestTime } from '~features/auth/otp/lib';
import { CreateApplicationError } from '~features/create-application-result';

import {
  type ApplicationDetails,
  upgradeApplicationIdAtom,
  useApplicationAsync,
} from '~entities/application';
import { userInfoAtom } from '~entities/auth';
import {
  useUpgradeInitiate,
  isSuccessResponse,
  isFindOrCreateApplicationError,
} from '~entities/auth';
import type { FindOrCreateApplicationError } from '~entities/auth';
import { useSearchOfferByPhone } from '~entities/offer';
import { selectedPosAtom } from '~entities/pos';

import { useOnMountEffect } from '~shared/hooks';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Step, Stepper } from '~shared/ui/stepper';

import { useSetAtom } from 'jotai';
import { useAtomValue } from 'jotai';

import {
  useHandleApplicationFlow,
  type ClientInitiationNavigationState,
} from '../lib';

const stepsNames = {
  clientPhone: 'clientPhone',
  smsConfirmation: 'smsConfirmation',
};

export const ClientInitiationPage = () => {
  const navigate = useNavigate();
  const { state: navigationState } = useLocation();
  const analytics = useCustomerAnalytics();
  const calculatorAnalytics = useCalculatorAnalytics();
  const { handleFlow } = useHandleApplicationFlow();
  const upgradeApplicationId = useSetAtom(upgradeApplicationIdAtom);
  const selectedPos = nonNullableValue(useAtomValue(selectedPosAtom));
  const userInfo = useAtomValue(userInfoAtom);
  const {
    loanCalculator,
    productType,
    calculatorSessionId,
    clientPhonePrefilled,
    isOfferCancelled,
  } = (navigationState as ClientInitiationNavigationState) || {};
  const clientPhone = loanCalculator?.offer?.offerPhone || clientPhonePrefilled;
  const shouldSearchForOffer =
    !loanCalculator?.offer?.id && !isOfferCancelled && productType === 'LOAN';

  const [phoneNumber, setPhoneNumber] = useState('');
  const [promoterCode, setPromoterCode] = useState<string>();
  const [stepName, setStepName] = useState(stepsNames.clientPhone);
  const [error, setError] = useState<
    Error | FindOrCreateApplicationError | null
  >(null);

  useOnMountEffect(() => {
    if (calculatorSessionId) {
      calculatorAnalytics.setUserId(calculatorSessionId);
    }
  });
  const handleApplicationSuccess = (application: ApplicationDetails) => {
    const { forcedOfferAppliedId } =
      (navigationState as ClientInitiationNavigationState) || {};
    upgradeApplicationId(application.id);

    if (loanCalculator) {
      calculatorAnalytics.trackCalculatorUpdate({
        employeeId: userInfo?.employeeId,
        posId: selectedPos?.id,
        merchantId: selectedPos?.merchantId,
        productType,
        paymentPlans: loanCalculator.paymentPlans,
        items: loanCalculator.items,
        offerId: loanCalculator.offer?.id,
        totalPrice: loanCalculator.totalPrice,
        productId: loanCalculator.productId,
        campaignId: loanCalculator.campaignId,
        downpayment: loanCalculator.downPayment,
        loanTerm: loanCalculator.period,
        isPhoneNumberEntered: true,
        applicationId: application.id,
      });
    }
    if (forcedOfferAppliedId) {
      analytics.trackForceOfferApplied({
        applicationId: application.id,
        offerId: forcedOfferAppliedId,
      });
    }
    handleFlow(application, { ...navigationState, promoterCode });
  };

  const upgradeInitiateMutation = useUpgradeInitiate();

  const { mutateAsync: getApplicationAsync, isPending: isGettingApplication } =
    useApplicationAsync();

  const getApplication = async () => {
    try {
      const application = await getApplicationAsync();

      handleApplicationSuccess(application);
    } catch (error) {
      setError(error as Error);
    }
  };

  const upgradeInitiate = (phoneNumber: string) => {
    upgradeInitiateMutation.mutate(
      {
        authParameters: {
          merchantId: selectedPos.merchantId,
          posId: selectedPos.id,
          phoneNumber,
          productType: 'POS_LOAN' as const,
        },
      },
      {
        onSuccess(response) {
          setOtpRequestTime('login', phoneNumber, new Date().getTime());
          // agent is already upgraded for this user
          if (isSuccessResponse(response)) {
            getApplication();
          } else {
            setStepName(stepsNames.smsConfirmation);
          }
        },
        onError(upgradeError) {
          setError(upgradeError);
        },
      }
    );
  };

  const offerMutation = useSearchOfferByPhone();

  const handleClientInfoSubmit = (phone: string, code?: string) => {
    setPhoneNumber(phone);
    if (code) {
      setPromoterCode(code.trim());
    }
    if (loanCalculator) {
      calculatorAnalytics.trackCalculatorUpdate({
        employeeId: userInfo?.employeeId,
        posId: selectedPos?.id,
        merchantId: selectedPos?.merchantId,
        productType,
        paymentPlans: loanCalculator.paymentPlans,
        items: loanCalculator.items,
        offerId: loanCalculator.offer?.id,
        totalPrice: loanCalculator.totalPrice,
        productId: loanCalculator.productId,
        campaignId: loanCalculator.campaignId,
        downpayment: loanCalculator.downPayment,
        loanTerm: loanCalculator.period,
        isPhoneNumberEntered: true,
      });
    }

    if (shouldSearchForOffer) {
      offerMutation.mutate(phone, {
        onSuccess(offer) {
          if (offer.status === 'SUCCESS') {
            const state: PreCalcNavigationState = {
              ...(navigationState || {}),
              forcedOffer: { offer, phone },
            };
            navigate('/loan-calculator', {
              replace: true,
              state,
            });
          } else {
            upgradeInitiate(phone);
          }
        },
        onError() {
          upgradeInitiate(phone);
        },
      });
      return;
    }

    upgradeInitiate(phone);
  };

  const handleClose = () => {
    if (error && isFindOrCreateApplicationError(error)) {
      navigate('/');
    } else {
      navigate('/loan-calculator', { state: navigationState });
    }
  };

  const getContent = () => {
    if (error) {
      return isFindOrCreateApplicationError(error) ? (
        <CreateApplicationError error={error} onClose={() => navigate('/')} />
      ) : (
        <AuthorizationError />
      );
    }

    return (
      <Stepper current={stepName}>
        <Step name={stepsNames.clientPhone}>
          <ScrollIntoView>
            <ClientPhone
              presetPhone={clientPhone}
              onSubmit={handleClientInfoSubmit}
              isLoading={
                offerMutation.isPending ||
                upgradeInitiateMutation.isPending ||
                isGettingApplication
              }
              showPromoterCode
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepsNames.smsConfirmation}>
          <ScrollIntoView>
            <ClientSmsConfirmation
              phoneNumber={nonNullableValue(phoneNumber)}
              onSuccess={getApplication}
              onError={setError}
            />
          </ScrollIntoView>
        </Step>
      </Stepper>
    );
  };

  return (
    <Screen
      header={
        <FormHeader
          onClick={handleClose}
          text={
            stepName === stepsNames.smsConfirmation ? 'Enter the\u00A0code' : ''
          }
        />
      }
    >
      {getContent()}
    </Screen>
  );
};
// eslint-enable-next-line max-statements
