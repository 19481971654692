import { addressSuggestionHandlers } from '~entities/address/api/mocks/mocks';
import { agentInfoHandlers } from '~entities/agent/api/mocks/mocks';
import { applicationHandlers } from '~entities/application/api/mocks/mocks';
import { attendanceHandlers } from '~entities/attendance/api/mocks/mock';
import { authHandlers } from '~entities/auth/api/mocks/mocks';
import { documnetHandlers } from '~entities/document/api/mocks/mocks';
import { ocrHandlers } from '~entities/file/api/mocks/mocks';
import { giftPaymentHandlers } from '~entities/gift-payment/api/mocks/mocks';
import { offerHandlers } from '~entities/offer/api/mocks/mocks';
import { posHandlers } from '~entities/pos/api/mocks/mocks';
import { productHandlers } from '~entities/product/api/mocks/mock';
import { scheduleHandlers } from '~entities/shedule/api/mocks/mocks';

import { HttpHandler } from 'msw';
import type { MockName, OverrideHandler } from '~tests/types/mock-handler';

export const handlers = {
  ...attendanceHandlers,
  ...productHandlers,
  ...addressSuggestionHandlers,
  ...ocrHandlers,
  ...applicationHandlers,
  ...offerHandlers,
  ...agentInfoHandlers,
  ...giftPaymentHandlers,
  ...posHandlers,
  ...scheduleHandlers,
  ...documnetHandlers,
  ...authHandlers,
} as const;

export const configuredHandlers: Record<MockName, HttpHandler> = Object.keys(
  handlers
).reduce(
  (acc: Record<MockName, HttpHandler>, handlerName: string) => {
    const handler = handlers[handlerName as MockName];
    if (typeof handler !== 'function') {
      throw new Error('MSW handler is not a function.');
    }

    acc[handlerName as MockName] = handler();
    return acc;
  },
  {} as Record<MockName, HttpHandler>
);

export const overrideInitialHandlers = () => {
  const overrides = window.msw?.initialHandlersOverrides;
  if (overrides) {
    const newHandlers = (Object.keys(overrides) as MockName[]).map(
      (handlerName) => {
        const params = overrides[handlerName];
        const handler = handlers[handlerName] as OverrideHandler;
        return handler(params);
      }
    );

    window.msw?.worker?.use(...newHandlers);
    window.msw!.initialHandlersOverrides = null;
  }
};
