import { useDi } from '~app/providers/di-provider';

import type { Enabled, Query } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { documentKeys } from './document-keys';

import type { Status } from '../api';

export const useGetAgreementStatusQuery = (options?: {
  enabled?: Enabled<Status, Error, Status>;
  refetchInterval?:
    | number
    | false
    | ((query: Query<Status, Error, Status>) => number | false | undefined)
    | undefined;
}) => {
  const { agreementGenerationApi } = useDi();
  return useQuery({
    queryKey: documentKeys.agreementStatus(),
    queryFn: () => agreementGenerationApi.getStatus(),
    gcTime: 0,
    ...options,
  });
};
