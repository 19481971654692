import { useDi } from '~app/providers/di-provider';

import { useQueryStatusHandlers } from '~shared/react-query-helpers/hooks/use-query-status-handlers';

import {
  Enabled,
  Query,
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { applicationsKeys } from './application-keys';

import { ApplicationDetails } from '../application';
import {
  isDownpaymentPending,
  isMobileAppRequired,
  isReviewingCustomer,
  isWaitingForDecision,
} from '../lib';

export const useApplicationList = (posId?: string) => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: applicationsKeys.listByPos(posId),
    queryFn: async () => {
      if (!posId) {
        return { pending: [], list: [] };
      }
      const list = await applicationApi.getApplicationList(posId);
      const pending: ApplicationDetails[] = [];
      list.forEach((application) => {
        // we fill cache for applications data => when the user opens application details page,
        // there is cached data for it already
        queryClient.setQueryData(
          applicationsKeys.application(application.id),
          application
        );
        if (isDownpaymentPending(application)) {
          pending.push(application);
        }
      });
      return { list, pending };
    },
    refetchInterval: 15000,
  });
};

export const useApplication = (options?: {
  enabled?: Enabled<ApplicationDetails, Error, ApplicationDetails>;
}) => {
  const { applicationApi } = useDi();

  return useQuery<ApplicationDetails>({
    queryKey: applicationsKeys.activeApplication(),
    queryFn: () => applicationApi.getApplication(),
    gcTime: 0,
    enabled: options?.enabled,
  });
};

export const useApplicationAsync = () => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();

  return useMutation<ApplicationDetails>({
    mutationFn: () => applicationApi.getApplication(),
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: applicationsKeys.activeApplication(),
      });
    },
  });
};

export const useApplicationById = (
  id?: string,
  options?: {
    enabled: Enabled<ApplicationDetails, Error, ApplicationDetails>;
  },
  resultHandlers?: {
    onSuccess?: (data: ApplicationDetails) => void;
    onError?: () => void;
  }
) => {
  const { applicationApi } = useDi();
  const queryResult = useQuery({
    queryKey: applicationsKeys.application(id ?? ''),
    queryFn: id ? () => applicationApi.getApplicationById(id) : skipToken,
    refetchInterval: ({ state }: Query<ApplicationDetails>) => {
      const shouldPollApplication =
        isWaitingForDecision(state.data) ||
        isMobileAppRequired(state.data) ||
        isReviewingCustomer(state.data);

      return shouldPollApplication ? 15000 : false;
    },
    ...options,
  });
  useQueryStatusHandlers({ queryResult, ...resultHandlers });

  return queryResult;
};
