import { useDi } from '~app/providers/di-provider';

import { useQueuedMutation } from '~shared/react-query-helpers';

import { PersonPatchUpdatePayload } from './person-api';

import { Person } from '../model';

export const useUpdatePerson = () => {
  const { personApi } = useDi();

  return useQueuedMutation({
    mutationFn: (payload: Partial<Person>) => personApi.updatePerson(payload),
  });
};

export const usePatchUpdatePerson = () => {
  const { personApi } = useDi();

  return useQueuedMutation({
    mutationFn: (payload: PersonPatchUpdatePayload) =>
      personApi.patchUpdatePerson(payload),
  });
};
