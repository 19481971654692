import {
  useAnalytics,
  useCustomerAnalytics,
} from '~app/providers/analytics-provider';

import {
  AmplitudeAnalytics,
  AmplitudeCustomerAnalytics,
} from '~entities/amplitude-analytics';

import { Enabled, useQuery } from '@tanstack/react-query';

import { authKeys } from './auth-keys';
import { gatewayAuthApi } from './gateway/gateway-auth-api';

import { UserInfo } from '../auth';

async function fetchUserInfo(
  analytics: AmplitudeAnalytics,
  customerAnalytics: AmplitudeCustomerAnalytics
) {
  const userInfo = await gatewayAuthApi.getUserInfo();
  analytics.setClientAnalyticsId(userInfo.clientAnalyticsPersonId);
  analytics.setUserId(userInfo.employeeId);
  customerAnalytics.setEmployeeId(userInfo.employeeId);
  window.clarity?.('upgrade', 'Agent is logged in');
  return userInfo;
}

export const useRestoredUserInfo = (options?: {
  enabled?: Enabled<UserInfo, Error, UserInfo>;
}) => {
  const analytics = useAnalytics();
  const customerAnalytics = useCustomerAnalytics();

  return useQuery({
    queryKey: authKeys.restored(),
    queryFn: () => fetchUserInfo(analytics, customerAnalytics),
    gcTime: 0,
    enabled: options?.enabled,
  });
};

export const useAgentUserInfo = (options?: {
  enabled?: Enabled<UserInfo, Error, UserInfo>;
}) => {
  const analytics = useAnalytics();
  const customerAnalytics = useCustomerAnalytics();

  return useQuery({
    queryKey: authKeys.agent(),
    queryFn: () => fetchUserInfo(analytics, customerAnalytics),
    gcTime: 0,
    enabled: options?.enabled,
  });
};

export const useClientInfo = (options?: {
  enabled?: Enabled<UserInfo, Error, UserInfo>;
}) => {
  const analytics = useAnalytics();
  const customerAnalytics = useCustomerAnalytics();

  return useQuery({
    queryKey: authKeys.client(),
    queryFn: () => fetchUserInfo(analytics, customerAnalytics),
    gcTime: 0,
    enabled: options?.enabled,
  });
};
