import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getProductTypeFromItems,
  recoverLoanCalculator,
} from '~widgets/loan-calculator';

import { ApplicationDetails } from '~entities/application';
import { SearchOfferResponse, useSearchOfferByPhone } from '~entities/offer';
import { selectedPosAtom } from '~entities/pos';

import { useAtomValue } from 'jotai';

type ContinueApplicationResponse = {
  continueApplication: () => void;
  isPending?: boolean;
};
export function useContinueApplication(
  applicationId: string,
  applicationDetails: ApplicationDetails
): ContinueApplicationResponse {
  const selectedPos = useAtomValue(selectedPosAtom);
  const productType = getProductTypeFromItems(
    !!selectedPos?.hasZeroRateProducts,
    applicationDetails.orderItems
  );
  const hasOffer = !!applicationDetails.offer?.id;
  const isZeroRate = productType === 'LOAN_ZERO_RATE';
  const shouldCheckForOffer = !hasOffer && !isZeroRate;

  const navigate = useNavigate();

  const searchOfferMutation = useSearchOfferByPhone();

  const goToApplication = useCallback(() => {
    navigate(
      hasOffer
        ? `/xsell-short-application/${applicationId}`
        : `/loan-application/${applicationId}`,
      { replace: true }
    );
  }, [applicationId, hasOffer, navigate]);

  const { mobilePhone } = applicationDetails.person;
  const { mutate } = searchOfferMutation;

  const handleSuccess = useCallback(
    (response: SearchOfferResponse) => {
      if (response.status === 'SUCCESS') {
        navigate('/loan-calculator', {
          state: {
            loanCalculator: recoverLoanCalculator(applicationDetails),
            forcedOffer: {
              offer: response,
              phone: applicationDetails.person.mobilePhone,
            },
          },
        });
      } else {
        goToApplication();
      }
    },
    [navigate, applicationDetails, goToApplication]
  );

  return {
    continueApplication: useCallback(
      function continueApplication() {
        if (shouldCheckForOffer) {
          mutate(mobilePhone, {
            onSuccess: handleSuccess,
            onError() {
              goToApplication();
            },
          });
        } else {
          goToApplication();
        }
      },
      [mutate, goToApplication, shouldCheckForOffer, mobilePhone, handleSuccess]
    ),
    isPending: searchOfferMutation.isPending,
  };
}
