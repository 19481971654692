import { useDi } from '~app/providers/di-provider';

import { selectedPosAtom } from '~entities/pos';

import { useQueuedMutation } from '~shared/react-query-helpers';

import {
  type UseMutationOptions,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import type {
  UpdateManufacturerIdsPayload,
  ConfirmProductParams,
} from './application-api';
import { applicationsKeys } from './application-keys';

import type { Application, ApplicationDetails } from '../application';
import { updateFinishedApplicationQueryData } from '../lib';

export class ProcessStatusError extends Error {
  name = 'PROCESS_STATUS_ERROR' as const;

  public constructor() {
    super(`Incorrect application process status`);
  }
}

export const useInitialApplicationUpdate = () => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (application: Partial<Application>) => {
      await applicationApi.updateApplication(application);

      const applicationData = queryClient.getQueryData(
        applicationsKeys.activeApplication()
      );
      if (applicationData) {
        queryClient.setQueryData(applicationsKeys.activeApplication(), {
          ...applicationData,
          ...application,
        });
      }
    },
  });
};

export const useUpdateApplication = () => {
  const { applicationApi } = useDi();

  return useQueuedMutation({
    mutationFn: (application: Partial<Application>) =>
      applicationApi.updateApplication(application),
  });
};

export const useUpdateManufacturerIds = (
  options?: UseMutationOptions<
    unknown,
    Error,
    UpdateManufacturerIdsPayload & { applicationId: string }
  >
) => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateManufacturerIdsPayload & { applicationId: string }
    ) => {
      await applicationApi.updateManufacturerIds({ items: payload.items });

      queryClient.invalidateQueries({
        queryKey: applicationsKeys.application(payload.applicationId),
      });
    },
    ...options,
  });
};

export const useUpdateMetadata = (
  options?: UseMutationOptions<
    unknown,
    Error,
    { metaData: Application['metaData']; applicationId: string }
  >
) => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: {
      metaData: Application['metaData'];
      applicationId: string;
    }) => {
      await applicationApi.updateMetadata({ metaData: payload.metaData });

      const application = queryClient.getQueryData<ApplicationDetails>(
        applicationsKeys.application(payload.applicationId)
      );
      queryClient.setQueryData(
        applicationsKeys.application(payload.applicationId),
        {
          ...application,
          metaData: payload.metaData,
        }
      );
    },
    ...options,
  });
};

export const useConfirmDownpayment = (
  options?: UseMutationOptions<unknown, Error, string>
) => {
  const { applicationApi } = useDi();
  const selectedPos = useAtomValue(selectedPosAtom);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (applicationId: string) => {
      const result = await applicationApi.confirmDownpayment();
      const updatedApplication: Partial<ApplicationDetails> = {
        applicationStatus: 'APPROVED',
        downPaymentVerified: true,
        offerSigned: true,
        applicationStatusChangedUTC: new Date().toISOString(),
        lastUpdatedUTC: new Date().toISOString(),
      };
      updateFinishedApplicationQueryData(
        queryClient,
        updatedApplication,
        applicationId,
        selectedPos?.id
      );

      return result;
    },
    ...options,
  });
};

export const useConfirmProduct = (id: string) => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: ConfirmProductParams) =>
      applicationApi.confirmProduct(params),
    onSuccess() {
      // Authorized application query cache should be reset because approvedProduct is added after product confirmation
      queryClient.resetQueries({
        queryKey: applicationsKeys.activeApplication(),
      });
      queryClient.resetQueries({
        queryKey: applicationsKeys.application(id),
      });
    },
  });
};
