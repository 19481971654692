import { atomWithBroadcast } from '~shared/atoms/atom-with-broadcast';

import * as Sentry from '@breeze/web-lib-sentry';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import type { ChallengeParameters } from '../api';
import type { UserInfo } from '../auth';
import { isEmployeeRoleAllowed } from '../lib/is-employee-role-allowed';

export const agentPhoneAtom = atomWithStorage<string | null>(
  'merchant_portal_agent_phone',
  null,
  undefined,
  { getOnInit: true }
);

const userInfoAtomInternal = atomWithBroadcast<UserInfo | null>({
  key: 'merchant_portal_user_info',
  initialValue: null,
});

export const userInfoAtom = atom<UserInfo | null>((get) =>
  get(userInfoAtomInternal)
);

export const updateUserInfoAtom = atom(
  null,
  (_get, set, userInfo: UserInfo | null) => {
    set(userInfoAtomInternal, userInfo);
    Sentry.setTag('employeeId', userInfo?.employeeId ?? null);
  }
);

export const isSessionRestoredAtom = atom(false);

export const isEmployeeRoleAllowedAtom = atom((get) =>
  isEmployeeRoleAllowed(get(userInfoAtom)?.employeeRole)
);

export const isAuthorizedAtom = atomWithBroadcast<boolean | null>({
  key: 'merchant_portal_is_authorized',
  initialValue: null,
});

export const lastInteractionTimeAtom = atomWithBroadcast({
  key: 'merchant_portal_last_interaction_time',
  initialValue: Date.now(),
});

export const employeeIdAtom = atom((get) => get(userInfoAtom)?.employeeId);

export const initiateChallengeParamsAtom =
  atomWithBroadcast<ChallengeParameters | null>({
    key: 'merchant_portal_initiate_challenge_params',
    initialValue: null,
  });
