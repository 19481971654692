import { selectedPosAtom } from '~entities/pos';

import { Enabled, useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { gatewayGiftPaymentsApi } from './gateway-gift-payments-api';
import type {
  GetGiftPaymentsParams,
  GiftPaymentSettings,
} from './gift-payments-api';
import { giftPaymentsKeys } from './gift-payments-keys';

export function useGiftPaymentsSettings<
  TSelect = GiftPaymentSettings[],
>(options?: {
  enabled?: Enabled<GiftPaymentSettings[], Error, GiftPaymentSettings[]>;
  select?: (response: GiftPaymentSettings[]) => TSelect;
}) {
  const selectedPos = useAtomValue(selectedPosAtom);
  const params: GetGiftPaymentsParams | undefined = selectedPos
    ? {
        posId: selectedPos.id,
        merchantId: selectedPos.merchantId,
      }
    : undefined;
  return useQuery({
    queryKey: giftPaymentsKeys.settingsByParams(params ?? {}),
    queryFn: () =>
      params ? gatewayGiftPaymentsApi.getGiftPaymentsSettings(params) : [],
    ...options,
  });
}

export function useGiftPaymentRatesByTerm(options?: {
  enabled?: Enabled<GiftPaymentSettings[], Error, GiftPaymentSettings[]>;
}) {
  return useGiftPaymentsSettings({
    select: (giftPayments) =>
      giftPayments.reduce(
        (result, value) => {
          result[value.termInMonths] = value.ratePercent;

          return result;
        },
        {} as Record<number, number>
      ),
    ...options,
  });
}
