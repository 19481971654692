import { useDi } from '~app/providers/di-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { getDeviceMetadata } from '~entities/device-metadata';

import { Enabled, useQuery } from '@tanstack/react-query';

import { ValidateResponse } from './auth-api';
import { authKeys } from './auth-keys';

export const useValidate = (options?: {
  enabled: Enabled<ValidateResponse, Error>;
}) => {
  const { authApi } = useDi();
  const tracking = useTracking();
  return useQuery({
    queryKey: authKeys.validate(),
    queryFn: async () => {
      return authApi.validate({
        webFingerprint: getDeviceMetadata(tracking?.state),
      });
    },
    gcTime: 0,
    ...options,
  });
};
