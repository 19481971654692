import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UpdateConversationDetailsPayload } from './marketing-leads-api';
import { gatewayMarketingLeadsApi } from './marketing-leads-api';
import { leadsKeys } from './marketing-leads-keys';

import type { MarketingLead } from '../marketing-lead';

export function useUpdateConversationDetails() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: UpdateConversationDetailsPayload) =>
      gatewayMarketingLeadsApi.updateConversationDetails(payload),
    onSuccess: (_response, payload) => {
      const { id, conversationDetails } = payload;
      const prevLeads = queryClient.getQueryData<MarketingLead[]>(
        leadsKeys.all()
      );
      const updatedLeads = prevLeads?.map((lead) =>
        lead.id === id ? { ...lead, conversationDetails } : lead
      );
      queryClient.setQueryData(leadsKeys.all(), updatedLeads);
    },
  });
}
