import { ButtonLink } from '@breeze-platform-ui/button';
import Tooltip from '@breeze-platform-ui/tooltip';

export const ShowPosHelp = () => {
  return (
    <Tooltip
      stopPropagationClick
      preventDefaultClick
      increasedTouchZone
      smartDirection
      theme="dark"
      popoverContent="If you can't find your store, please contact your ASM"
    >
      <ButtonLink size="s" disableHorizontalPadding disableVerticalPadding>
        Can’t find my store
      </ButtonLink>
    </Tooltip>
  );
};
