import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { ValidateResponse } from '../auth-api';

export const getTokenValidate = (
  params?: HandlerParams<ValidateResponse, 'Unauthorized'>
) =>
  http.post('*/auth/api/v1/merchant_authorizer/tokens/validate', async () => {
    if (params?.scenario === 'Unauthorized') {
      return HttpResponse.json(mockedErrorResponses.UNAUTHORIZED, {
        status: 401,
      });
    }
    return HttpResponse.json(
      mockSuccessResponse(
        params?.payload ?? {
          tokenUse: 'access',
          scope: 6,
          auth: '2024-01-10T07:24:14.864673366Z',
          refresh: '2024-01-10T07:30:14.864673366Z',
          exp: '2024-01-10T07:36:14.864673366Z',
          refreshRemainingMinutes: 6,
          expRemainingMinutes: 12,
          otpMockValue: '',
        }
      )
    );
  });
