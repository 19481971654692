import { atomWithBroadcast } from '~shared/atoms/atom-with-broadcast';

import * as Sentry from '@breeze/web-lib-sentry';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const activeApplicationIdAtomInternal = atomWithBroadcast<string | null>({
  key: 'merchant_portal_active_application_id',
  initialValue: null,
});

export const activeApplicationIdAtom = atom((get) =>
  get(activeApplicationIdAtomInternal)
);

export const downgradeApplicationIdAtom = atom(null, (get, set, update) => {
  if (get(activeApplicationIdAtomInternal) === update) {
    set(activeApplicationIdAtomInternal, null);
    Sentry.setTag('applicationId', null);
  }
});

export const upgradeApplicationIdAtom = atom(
  null,
  (_get, set, update: string) => {
    set(activeApplicationIdAtomInternal, update);
    Sentry.setTag('applicationId', update);
  }
);

export const pendingNotificationViewTimeAtom = atomWithStorage<number | null>(
  'pending_notification_view_time',
  null,
  undefined,
  { getOnInit: true }
);
