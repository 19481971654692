import { ApplicationDetailsPage } from '~pages/application-details';

import { SelectedPosGuardHOC } from '~features/select-pos';

export default function ApplicationDetails() {
  return (
    <SelectedPosGuardHOC>
      <ApplicationDetailsPage />
    </SelectedPosGuardHOC>
  );
}
