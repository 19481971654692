import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { AgentInfo } from '../../agent';

export const getEmployeeById = (params?: HandlerParams<AgentInfo>) =>
  http.post('*/companies/api/v1/web/employee/get-by-id', async () => {
    return HttpResponse.json(
      mockSuccessResponse<AgentInfo>(
        params?.payload ?? {
          employeeId: '5e5facde-7d90-465b-b80e-2ddc981beb08',
          companyId: 'a9ebe7fe-7c9a-4182-94ae-ab464a0bcd8f',
          employmentType: 'FULL_TIME',
        }
      )
    );
  });

export const getEmployeeByIdError = http.post(
  '*/companies/api/v1/web/employee/get-by-id',
  async () => {
    return HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    });
  }
);
