import { Controller, useForm } from 'react-hook-form';

import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { Col } from '@breeze-platform-ui/layout';
import { RadioGroup } from '@breeze/rhf-adapters';

import { ephilVerificationOptions } from '../lib/ephil-verification-options';

export type FormValues = {
  ephilIdVerified: boolean;
};

type Props = {
  name: string;
  onPrev: () => void;
  onClose: () => void;
  onSubmit: (formValues: FormValues) => void;
  initialValue?: FormValues;
};

export const EphilVerificationForm = ({
  name,
  onClose,
  onPrev,
  onSubmit,
  initialValue,
}: Props) => {
  const methods = useForm<FormValues>({
    defaultValues: initialValue ?? {},
    mode: 'all',
  });
  const { control, handleSubmit } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={onPrev} />}
      >
        <Col gaps={20} alignCross="stretch">
          <ScreenTitle
            title="Verify ePhil&nbsp;ID on&nbsp;the&nbsp;PhilSys 
website"
            subtitle="This step is&nbsp;required. To&nbsp;verify, scan the&nbsp;QR code at&nbsp;the&nbsp;back of&nbsp;the&nbsp;ePhil&nbsp;ID"
          />

          <Controller
            control={control}
            name="ephilIdVerified"
            rules={{
              required: 'Choose one option',
            }}
            render={(fieldProps) => (
              <RadioGroup
                {...fieldProps}
                options={ephilVerificationOptions}
                vertical
                wide
              />
            )}
          />
        </Col>
      </Screen>
    </form>
  );
};
