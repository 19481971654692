import { useDi } from '~app/providers/di-provider';

import { useQuery } from '@tanstack/react-query';

import { personKeys } from './person-keys';

export const useCheckProfile = () => {
  const { personApi } = useDi();

  return useQuery({
    queryKey: personKeys.checkProfile(),
    queryFn: async () => {
      try {
        return await personApi.checkProfile();
      } catch (_err) {
        return { profileFilled: false };
      }
    },
    gcTime: 0,
  });
};
