import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { getMaxItemsAmount } from '~features/manage-order-items/lib/get-max-items-amount';

import type { OrderItem } from '~entities/order-item';
import {
  OrderItemList,
  calculateItemsCount,
  calculateItemsPrice,
} from '~entities/order-item';
import {
  isItemNotFoundError,
  isZeroRate,
  useZeroRateItems,
} from '~entities/product';
import type { ProductType } from '~entities/product';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Loader } from '~shared/ui/loader/loader';
import { SectionTitle } from '~shared/ui/section-title';

import Button from '@breeze-platform-ui/button';
import { Int16Plus } from '@breeze-platform-ui/iconsPack';
import { Col } from '@breeze-platform-ui/layout';
import { keepPreviousData } from '@tanstack/react-query';

import { addOrderItem } from '../../lib/add-order-item';
import { EmptyList } from '../empty-list/empty-list';
import { LoanItemForm } from '../loan-item-form/loan-item-form';
import { OrderItemActions } from '../order-item-actions/order-item-actions';
import { ValidationWarning } from '../validation-warning';
import { ZeroRateItemForm } from '../zero-rate-item-form/zero-rate-item-form';

type Props = {
  type: ProductType;
  orderItems?: OrderItem[];
  minPrice?: number;
  maxPrice?: number;
  onChangeItems: (products: OrderItem[]) => void;
};

export const ManageOrderItems = ({
  type,
  orderItems = [],
  minPrice,
  maxPrice,
  onChangeItems,
}: Props) => {
  const selectedItemIndexRef = useRef<number | null>(null);
  const editedItem =
    selectedItemIndexRef.current !== null
      ? orderItems[selectedItemIndexRef.current]
      : undefined;
  const [itemFormOpened, setItemFormOpened] = useState<boolean>(false);

  const {
    data: zeroRateItems,
    isFetching,
    isPlaceholderData: isPreviousData,
    isLoading,
    error: getZeroRateItemsError,
  } = useZeroRateItems(
    useMemo(
      () =>
        orderItems
          .map((item) => nonNullableValue(item.zeroRateItemId))
          .filter((id) => id && id !== editedItem?.zeroRateItemId),
      [orderItems, editedItem?.zeroRateItemId]
    ),
    {
      enabled: isZeroRate(type),
      placeholderData: keepPreviousData,
    }
  );

  useEffect(() => {
    if (getZeroRateItemsError && isItemNotFoundError(getZeroRateItemsError)) {
      onChangeItems([]);
    }
  }, [getZeroRateItemsError, onChangeItems]);

  // if the first added item has multichoice, then the current
  // campaign has multichoice and all other items will have multichoice
  const firstZeroRateItemId = orderItems[0]?.zeroRateItemId;
  const hasMultiChoice =
    !!firstZeroRateItemId &&
    zeroRateItems?.[firstZeroRateItemId]?.eligibleForMultiChoice;
  const maxItemsAmount = getMaxItemsAmount(type, hasMultiChoice);

  const handleDeleteProduct = (index: number) =>
    onChangeItems(
      orderItems.slice(0, index).concat(orderItems.slice(index + 1))
    );

  const handleEditProduct = (index: number) => {
    setItemFormOpened(true);
    selectedItemIndexRef.current = index;
  };

  const handleItemFormClosed = () => {
    setItemFormOpened(false);
    selectedItemIndexRef.current = null;
  };

  const handleSubmitItem = (item: OrderItem) => {
    let newItems = [...orderItems];

    if (selectedItemIndexRef.current !== null) {
      // the user has edited the product => the easiest way is
      // to remove its previous version and add a new one to
      // escape duplication problems
      newItems = newItems.filter(
        (_, index) => index !== selectedItemIndexRef.current
      );
    }

    onChangeItems(addOrderItem(item, newItems));
    handleItemFormClosed();
  };

  const totalPrice = useMemo(
    () => calculateItemsPrice(orderItems),
    [orderItems]
  );
  const itemsCount = useMemo(
    () => calculateItemsCount(orderItems),
    [orderItems]
  );

  const totalItemsAmount = itemsCount - (editedItem?.quantity ?? 0);

  /**
   * TODO: make orderItems a field array and a part of
   * the main loan details form
   */
  if (itemFormOpened) {
    // we can't nest <form> inside <form>, so item form
    // should be rendered outside main application form
    return createPortal(
      isZeroRate(type) ? (
        <ZeroRateItemForm
          initialValue={editedItem}
          zeroRateItems={zeroRateItems ?? {}}
          isLoading={isFetching}
          totalItemsAmount={totalItemsAmount}
          onSubmit={handleSubmitItem}
          onCancel={handleItemFormClosed}
        />
      ) : (
        <LoanItemForm
          onSubmit={handleSubmitItem}
          totalItemsAmount={totalItemsAmount}
          initialValue={editedItem}
          onCancel={handleItemFormClosed}
        />
      ),
      document.body
    );
  }

  const showLoader =
    ((isFetching && isPreviousData) || isLoading) && isZeroRate(type);

  return (
    <>
      <div style={{ position: 'relative' }}>
        {showLoader && <Loader overlay />}
        <Col gaps={16} alignCross="stretch">
          {orderItems.length > 0 ? (
            <>
              <SectionTitle title="Items to buy" />
              <OrderItemList
                items={orderItems}
                actions={(index: number) => (
                  <OrderItemActions
                    onDelete={() => handleDeleteProduct(index)}
                    onEdit={() => handleEditProduct(index)}
                  />
                )}
              />
              <ValidationWarning
                totalPrice={totalPrice}
                itemsCount={itemsCount}
                maxItemsCount={maxItemsAmount}
                maxPrice={maxPrice}
                minPrice={minPrice}
              />
            </>
          ) : (
            <EmptyList />
          )}

          {itemsCount < maxItemsAmount && (
            <Button
              type="button"
              theme="secondary"
              size="l"
              wide
              icon={<Int16Plus theme={{ color: 'var(--tds-color-primary)' }} />}
              onClick={() => setItemFormOpened(true)}
            >
              Add an item
            </Button>
          )}
        </Col>
      </div>
    </>
  );
};
