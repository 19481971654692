import { useParams, useNavigate } from 'react-router-dom';

import { AgentNewsItemScreen } from '~widgets/agent-news-item';

import { agentNewsApi, isSurvey } from '~entities/agent-news';

import { BasicHeader } from '~shared/ui/basic-header';
import { Loader } from '~shared/ui/loader';
import { NavCloseButton } from '~shared/ui/nav-close-button';
import { Screen } from '~shared/ui/screen';

import Text from '@breeze-platform-ui/text';

export const AgentNewsItemPage = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const newsDetailsQuery = agentNewsApi.useAgentsNewsItem({ id });

  const shouldRequestSurveyData =
    !!newsDetailsQuery.data && isSurvey(newsDetailsQuery.data);

  const surveyDataQuery = agentNewsApi.useSurvey(
    shouldRequestSurveyData ? id : null
  );

  const isLoading =
    newsDetailsQuery.isLoading ||
    (shouldRequestSurveyData && surveyDataQuery.isLoading);
  const isSuccess =
    newsDetailsQuery.isSuccess &&
    (!shouldRequestSurveyData || surveyDataQuery.isSuccess);
  const isError = newsDetailsQuery.isError || surveyDataQuery.isError;

  const getContent = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (isSuccess) {
      return (
        <AgentNewsItemScreen
          item={newsDetailsQuery.data}
          surveyData={
            surveyDataQuery.isSuccess ? surveyDataQuery.data : undefined
          }
          isBlocking={false}
        />
      );
    }
    if (isError) {
      return (
        <Text size={15} align="center">
          Couldn’t load the news. Please try again later.
        </Text>
      );
    }
  };

  return (
    <Screen
      header={
        <BasicHeader
          actionButton={
            <NavCloseButton onClick={() => navigate('/agent-news')} />
          }
        />
      }
    >
      {getContent()}
    </Screen>
  );
};
