import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import '~app/app.module.css';
import Layout from '~app/layout-wrapper';
import { overrideInitialHandlers } from '~app/mocks/handlers.ts';

import * as Sentry from '@breeze/web-lib-sentry';
import { shim as shimArrayAt } from 'array.prototype.at';
import { shim as shimPromiseWithResolvers } from 'promise.withresolvers';

import './global.css';
import AccessDenied from './routes/access-denied';
import MyAccount from './routes/account';
import AgentIncentive from './routes/agent-incentive';
import AgentNewsList from './routes/agent-news';
import AgentNewsItem from './routes/agent-news/[id]';
import Applications from './routes/applications';
import ApplicationDetails from './routes/applications/[id]';
import AgreementReview from './routes/applications/[id]/agreement-review';
import AgreementSigning from './routes/applications/[id]/agreement-signing';
import DownpaymentConfirmation from './routes/applications/[id]/downpayment-confirmation';
import LoanDetails from './routes/applications/[id]/loan-details';
import ProductConfirmation from './routes/applications/[id]/product-confirmation';
import ClientConfirmation from './routes/client-confirmation';
import ClientInitiation from './routes/client-initiation';
import LoanApplication from './routes/loan-application';
import LoanCalculator from './routes/loan-calculator';
import Login from './routes/login';
import MissedCheckout from './routes/missed-checkout';
import MobileSelfAppRedirect from './routes/mobile-self-app-redirect';
import NewSocialLead from './routes/new-social-lead';
import PosSelect from './routes/pos-select';
import SelfApplicationAgent from './routes/self-application-agent';
import SkyroLead from './routes/skyro-lead';
import SkyroLeads from './routes/skyro-leads';
import SocialLead from './routes/social-lead-details';
import SocialLeads from './routes/social-leads';
import XsellShortApplication from './routes/xsell-short-application';

import { worker } from '../src/app/mocks/browser.ts';

shimArrayAt();
shimPromiseWithResolvers();

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '*',
        element: <p>Not Found</p>,
      },
      {
        path: '',
        element: <MyAccount />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'access-denied',
        element: <AccessDenied />,
      },
      {
        path: 'client-initiation',
        element: <ClientInitiation />,
      },
      {
        path: 'client-confirmation',
        element: <ClientConfirmation />,
      },
      {
        path: 'pos-select',
        element: <PosSelect />,
      },
      {
        path: 'account',
        element: <MyAccount />,
      },
      {
        path: 'agent-incentive',
        element: <AgentIncentive />,
      },
      {
        path: 'agent-news',
        element: <AgentNewsList />,
      },
      {
        path: 'agent-news/:id',
        element: <AgentNewsItem />,
      },
      {
        path: 'skyro-leads',
        element: <SkyroLeads />,
      },
      {
        path: 'skyro-leads/:id',
        element: <SkyroLead />,
      },
      {
        path: 'social-leads',
        element: <SocialLeads />,
      },
      {
        path: 'social-leads/:id',
        element: <SocialLead />,
      },
      {
        path: 'social-leads/new',
        element: <NewSocialLead />,
      },
      {
        path: 'loan-calculator',
        element: <LoanCalculator />,
      },
      {
        path: 'loan-application/:id',
        element: <LoanApplication />,
      },
      {
        path: 'self-application-agent/:id',
        element: <SelfApplicationAgent />,
      },
      {
        path: 'mobile-self-app-redirect/:id',
        element: <MobileSelfAppRedirect />,
      },
      {
        path: 'xsell-short-application/:id',
        element: <XsellShortApplication />,
      },
      {
        path: 'applications',
        element: <Applications />,
      },
      {
        path: 'applications/:id',
        element: <ApplicationDetails />,
      },
      {
        path: 'applications/:id/agreement-review',
        element: <AgreementReview />,
      },
      {
        path: 'applications/:id/agreement-signing',
        element: <AgreementSigning />,
      },
      {
        path: 'applications/:id/downpayment-confirmation',
        element: <DownpaymentConfirmation />,
      },
      {
        path: 'applications/:id/loan-details',
        element: <LoanDetails />,
      },
      {
        path: 'applications/:id/product-confirmation',
        element: <ProductConfirmation />,
      },
      {
        path: 'missed-checkout',
        element: <MissedCheckout />,
      },
    ],
  },
]);

const enableMocking = async () => {
  // TODO: Remove window.TURN_ON_MSW when all integration tests use MSW
  if (!window.TURN_ON_MSW) {
    return;
  }

  overrideInitialHandlers();

  await worker.start({
    onUnhandledRequest: 'bypass',
  });
};

const root = document.getElementById('root') as HTMLElement;

enableMocking().then(() => {
  ReactDOM.createRoot(root).render(<RouterProvider router={router} />);
});
