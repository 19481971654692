import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { environment } from '~app/environment';

import { activeApplicationIdAtom } from '~entities/application';
import { employeeIdAtom } from '~entities/auth';

import { useAtomValue } from 'jotai';

export function CustomClarityTagsWrapper({ children }: PropsWithChildren) {
  const employeeId = useAtomValue(employeeIdAtom);
  const applicationId = useAtomValue(activeApplicationIdAtom);
  const location = useLocation();

  useEffect(() => {
    window.clarity?.('set', 'app_version', environment.VERSION);
  }, [location.key]);

  useEffect(() => {
    if (employeeId) {
      window.clarity?.('set', 'employee_id', employeeId);
    }
  }, [location.key, employeeId]);

  useEffect(() => {
    if (applicationId) {
      window.clarity?.('set', 'application_id', applicationId ?? undefined);
    }
  }, [location.key, applicationId]);

  return <>{children}</>;
}
