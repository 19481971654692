import {
  ApplicationFormatter,
  ApplicationRecovery,
  ApplicationRecoveryMapping,
  formatApplicationValuesByMapping,
  recoverApplicationValuesByMapping,
} from '~entities/application';

import { formatYesNo, recoverYesNo } from '~shared/formatters';

import { FormValues } from '../ui/ephil-verification-form';

const mapping: ApplicationRecoveryMapping<FormValues> = {
  ephilIdVerified: {
    path: ['metaData', 'ephilIdVerified'],
    format: formatYesNo,
    recover: recoverYesNo,
  },
};

export const formatEphilVerification: ApplicationFormatter<FormValues> = (
  application,
  screenValues
) => formatApplicationValuesByMapping(application, screenValues, mapping);

export const recoverEphilVerification: ApplicationRecovery<FormValues> = (
  application
) => recoverApplicationValuesByMapping(application, mapping);
