import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { environment } from '~app/environment';

import { RemoteReporter, logger } from '@tinkoff/logger';
import request from '@tinkoff/request-core';
import http from '@tinkoff/request-plugin-protocol-http';

import * as Sentry from '@breeze/web-lib-sentry';
import { findOrCreateWuid } from '@breeze/web-lib-wuid';

const collectRequest = request([http()]);

function initialize() {
  Sentry.initSentry({
    dsn: environment.SENTRY_HOST,
    release: environment.VERSION,
    environment: environment.ENV,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    replaysOnErrorSampleRate: 1.0,
  });
  Sentry.setUser({ id: findOrCreateWuid() });
}

const logsReporter = new RemoteReporter({
  emitLevels: {
    error: true,
    fatal: true,
  },
  async makeRequest(logObj) {
    return collectRequest({
      httpMethod: 'POST',
      type: 'json',
      url: environment.LOGS_COLLECTOR_HOST,
      payload: {
        appName: 'web-app-pos',
        wuid: typeof window === 'undefined' ? '' : findOrCreateWuid(),
        ...logObj,
      },
    });
  },
});

initialize();
logger.setLevel('error');
logger.addReporter(logsReporter);
logger.addReporter(Sentry.getSentryReporter());

export { logger } from '@tinkoff/logger';
