import type { RadioOption } from '~shared/types/radio-option';

const options = [
  {
    label: 'I’ve verified the ID',
    value: 'yes',
  },
  {
    label: "Couldn't verify the ID",
    value: 'no',
  },
] as const;

export type EphilVerificationTypeValue = (typeof options)[number]['value'];
export type EphilVerificationTypeTitle = (typeof options)[number]['label'];
export const ephilVerificationOptions =
  options as unknown as RadioOption<EphilVerificationTypeValue>[];
