import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import type { Product } from '../../product';

export const getProduct = (params?: HandlerParams<Product, 'MinLoanError'>) => {
  return http.post(
    '*/merchant-portal/api/v2/public/products/get-by-parameters',
    async () => {
      if (params?.scenario === 'MinLoanError') {
        return HttpResponse.json(
          mockSuccessResponse({
            code: 'SUM_TOO_LOW',
            message:
              'There is no products with requested sum. Min order amount=[3000]',
            minOrderTotalAmount: 3000,
            maxOrderTotalAmount: 25000,
          })
        );
      }

      return HttpResponse.json(
        mockSuccessResponse<Product>(
          params?.payload ??
            ({
              productId: '01GM5FTC6SKH1QAW9VX5MPJDM6',
              type: 'LOAN',
              channel: 'OFFLINE',
              minLoanAmount: 4286,
              maxLoanAmount: 25000,
              minDownPaymentPercent: 30,
              maxDownPaymentPercent: 90,
              termsInMonths: [6, 9, 12],
              minMonthlyInterestRate: 5.9,
              maxMonthlyInterestRate: 5.9,
              minOriginationFeeFlat: 790,
              maxOriginationFeeFlat: 790,
              minOriginationFeeRate: 20,
              maxOriginationFeeRate: 20,
            } as Product)
        )
      );
    }
  );
};

export const getProductError = http.post(
  '*/merchant-portal/api/v2/public/products/get-by-parameters',
  async () => {
    return HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    });
  }
);
