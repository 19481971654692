import { environment } from '~app/environment';

import { makeRequest } from '~shared/scp-client';

import { useQuery } from '@tanstack/react-query';

import { AgentNewsDetailsDto } from './api-types';
import { agentsNewsKeys } from './keys';

import { getItemDetailsFromDto } from '../model/get-agent-news-item-from-dto';

type Params = {
  id: string;
};

export async function fetchAgentNewsItem(id: string) {
  const response = await makeRequest<{ data: AgentNewsDetailsDto }>({
    httpMethod: 'GET',
    url: `${environment.API_HOST}/cms/api/v1/resources/agent-news-items/${id}`,
    credentials: 'include',
  });

  return getItemDetailsFromDto(response.data);
}

export function useAgentsNewsItem(params: Params) {
  const { id } = params;

  return useQuery({
    queryKey: agentsNewsKeys.newsItem(id),
    queryFn: () => fetchAgentNewsItem(id),
  });
}
