import { useDi } from '~app/providers/di-provider';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateApplicationVasListPayload } from './vas-api';
import { vasKeys } from './vas-keys';

export const useUpdateApplicationVasList = () => {
  const { vasApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: UpdateApplicationVasListPayload) =>
      vasApi.updateApplicationVasList(payload),
    onSuccess: (_response, payload) => {
      queryClient.setQueryData(vasKeys.list(payload.applicationId), payload);
    },
  });
};
