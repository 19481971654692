import { setupWorker } from 'msw/browser';
import { WindowMSW } from '~tests/types/mock-handler';

import { handlers } from './handlers';

export const worker = setupWorker(
  ...Object.values(handlers).map((handler) => handler())
);

window.msw = { ...(window.msw ?? {}), worker, handlers } as WindowMSW;
