import { useDi } from '~app/providers/di-provider';

import { selectedPosAtom } from '~entities/pos';

import { RequestError } from '~shared/scp-client';

import {
  Enabled,
  PlaceholderDataFunction,
  skipToken,
  useQuery,
} from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { isItemNotFoundError } from './errors';
import { productKeys } from './product-keys';

import { ZeroRateItem } from '../product';

export const useZeroRateItems = (
  selectedItemIds: string[] = [],
  options?: {
    enabled: Enabled<
      Record<string, ZeroRateItem>,
      Error,
      Record<string, ZeroRateItem>
    >;
    placeholderData: PlaceholderDataFunction<
      Record<string, ZeroRateItem>,
      Error,
      Record<string, ZeroRateItem>
    >;
  }
) => {
  const { productApi } = useDi();
  const selectedPos = useAtomValue(selectedPosAtom);

  return useQuery({
    queryKey: productKeys.selectedZeroRateItems(
      selectedPos?.id ?? '',
      selectedItemIds
    ),
    queryFn: selectedPos
      ? async () => {
          if (!selectedPos.hasZeroRateProducts) {
            return {};
          }

          const items = await productApi.getZeroRateItems({
            pointOfSaleId: selectedPos.id,
            selectedItemIds,
          });

          return items.reduce(
            (result, item) => {
              result[item.id] = item;

              return result;
            },
            {} as Record<string, ZeroRateItem>
          );
        }
      : skipToken,
    retry: (count, error) => {
      if (isItemNotFoundError(error as RequestError)) {
        return false;
      }

      return count < 3;
    },
    refetchInterval: 15 * 60 * 1000,
    ...options,
  });
};
