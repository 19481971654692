/**
 * Returns total loan amount
 * @param totalPrice - total price of all added products and services
 * @param downPayment - selected downPayment
 * @returns
 */
export function calculateLoanAmount(
  totalPrice: number,
  downPayment: number
): number {
  return totalPrice - downPayment;
}
/**
 * Рассчитывает сумму коммиссии за кредит
 *
 * @param loanAmount сумма кредита, запрошенная заемщиком
 * @param originationFeeRate комиссия, %
 * @param originationFeeFlat комиссия, стандартный сбор
 * @returns
 */
function calculateFee(
  loanAmount: number,
  originationFeeRate: number,
  originationFeeFlat: number
): number {
  return originationFeeFlat + loanAmount * (originationFeeRate / 100);
}

/**
 * Рассчитывает сумму кредита с учетом коммиссии
 *
 * @param loanAmount сумма кредита, запрошенная заемщиком
 * @param originationFeeRate комиссия, %
 * @param originationFeeFlat комиссия, стандартный сбор
 * @returns
 */
export function calculateLoanWithFee(
  loanAmount: number,
  originationFeeRate: number,
  originationFeeFlat: number
): number {
  const originationFee = calculateFee(
    loanAmount,
    originationFeeRate,
    originationFeeFlat
  );

  return loanAmount + originationFee;
}

/**
 * Рассчитывает сумму ежемесячного платежа
 *
 * @param loanAmount сумма кредита, запрошенная заемщиком
 * @param termInMonths количество платежей
 * @param monthlyInterestRate месячная процентная ставка %
 * @param originationFeeRate комиссия, %
 * @param originationFeeFlat комиссия, стандартный сбор
 * @returns
 */
export function calculateMonthlyPayment(
  loanAmount: number,
  termInMonths: number,
  monthlyInterestRate: number,
  originationFeeRate: number,
  originationFeeFlat: number
): number {
  const interestRateFraction = monthlyInterestRate / 100;

  const loanWithFees = calculateLoanWithFee(
    loanAmount,
    originationFeeRate,
    originationFeeFlat
  );

  if (monthlyInterestRate === 0) {
    return Math.ceil(loanWithFees / termInMonths);
  }

  const monthlyPaymentRaw =
    (loanWithFees *
      interestRateFraction *
      (1 + interestRateFraction) ** termInMonths) /
    ((1 + interestRateFraction) ** termInMonths - 1);

  // round up
  const monthlyPaymentRounded = Math.ceil(monthlyPaymentRaw);

  return monthlyPaymentRounded;
}
