import { nonNullableValue } from '~shared/types/non-nullable-value';

import { createMachine } from 'xstate';

import type { FormValues, StepName } from '../lib';
import { stepNames } from '../lib';

const getIdDocumentType = ({ idDocumentType }: FormValues) =>
  nonNullableValue(idDocumentType?.typeOther || idDocumentType?.typeMain);

export const StepFlowMachine = (
  initialStep: StepName
): ReturnType<typeof createMachine> => {
  return createMachine({
    id: 'cash-loan-application-form',
    predictableActionArguments: true,
    initial: initialStep,
    states: {
      [stepNames.idDocumentType]: {
        on: {
          next: { target: stepNames.idDocument },
        },
      },
      [stepNames.idDocument]: {
        on: {
          next: [
            {
              target: stepNames.additionalIdDocument,
              cond: (formValues: any) => {
                const type = getIdDocumentType(formValues);

                return !['UMID', 'SSS', 'E_PHIL_ID'].includes(type);
              },
            },
            {
              target: stepNames.clientPhoto,
              cond: (formValues: any) => {
                const type = getIdDocumentType(formValues);

                return ['UMID', 'SSS'].includes(type);
              },
            },
            {
              target: stepNames.ephilVerificationLink,
              cond: (formValues: any) => {
                const type = getIdDocumentType(formValues);

                return 'E_PHIL_ID' === type;
              },
            },
          ],
        },
      },
      [stepNames.ephilVerificationLink]: {
        on: {
          next: {
            target: stepNames.ephilVerificationForm,
          },
        },
      },
      [stepNames.ephilVerificationForm]: {
        on: {
          next: {
            target: stepNames.additionalIdDocument,
          },
        },
      },
      [stepNames.additionalIdDocument]: {
        on: {
          next: {
            target: stepNames.clientPhoto,
          },
        },
      },
      [stepNames.clientPhoto]: {
        on: {
          next: {
            target: stepNames.gCashDetails,
          },
        },
      },

      [stepNames.gCashDetails]: {
        on: {
          next: {
            target: stepNames.clientAssessment,
          },
        },
      },
      [stepNames.clientAssessment]: {
        type: 'final',
      },
    },
  });
};
