import { useForm, Controller } from 'react-hook-form';

import { useCancelApplication } from '~entities/application';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { LoadingButton } from '@breeze-platform-ui/button';
import { Int24Close } from '@breeze-platform-ui/iconsPack';
import { Row, Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { RadioGroup, Textarea } from '@breeze/rhf-adapters';

import styles from './cancellation-survey.module.css';

import {
  cancellationReasonOptions,
  cancellationReasonCodeMap,
  type CancellationReasonValue,
} from '../lib';

type FormValues = {
  reason: CancellationReasonValue;
  comment?: string;
};

type Props = {
  applicationId: string;
  onClose: () => void;
  onCancel: () => void;
};

export const CancellationSurvey = ({
  applicationId,

  onClose,
  onCancel,
}: Props) => {
  const { control, watch, handleSubmit } = useForm<FormValues>({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {},
  });
  const { mutate, isPending } = useCancelApplication({
    onSuccess: onCancel,
  });
  const cancellationReason = watch('reason');
  const cancellationComment = watch('comment');

  const isOtherReason = cancellationReason === 'Others';
  const isSubmitDisabled =
    !cancellationReason || (isOtherReason && !cancellationComment);

  const onSubmit = ({ reason, comment }: FormValues) => {
    const rejectDescription = (isOtherReason ? comment : reason) || '';
    mutate({
      applicationId,
      rejectDescription,
      rejectReasonCode: cancellationReasonCodeMap[reason],
    });
  };

  return (
    <form className={styles.cancellation} onSubmit={handleSubmit(onSubmit)}>
      <Col gaps="s" alignCross="stretch">
        <Row alignMain="between" alignCross="center">
          <Text bold size={20}>
            Why do you want to&nbsp;cancel?
          </Text>
          <Int24Close
            theme={{ color: 'var(--tds-color-base-06)' }}
            cursor="pointer"
            onClick={() => onClose()}
          />
        </Row>
        <Controller
          name="reason"
          control={control}
          rules={{
            required: 'Select an option',
          }}
          render={(fieldProps) => (
            <RadioGroup
              {...fieldProps}
              options={cancellationReasonOptions}
              vertical
              noWrapper
              reverse
              borderType="under"
            />
          )}
        />
        {isOtherReason && (
          <Controller
            name="comment"
            control={control}
            rules={{
              validate: combineValidators(
                validators.required({ text: 'Enter the reason' }),
                validators.maxLength({ maxLength: 200 }),
                validators.latinOnly()
              ),
            }}
            render={(fieldProps) => (
              <Textarea label="Tell us the&nbsp;reason" {...fieldProps} />
            )}
          />
        )}

        <LoadingButton
          size="m"
          wide
          type="submit"
          disabled={isSubmitDisabled}
          loading={isPending}
        >
          Submit
        </LoadingButton>
      </Col>
    </form>
  );
};
