import { useNavigate, useParams } from 'react-router-dom';

import { ApplicationAgreementSingning } from '~widgets/application-agreement-singning';

import {
  applicationsKeys,
  shouldVerifyDownPayment,
  useApplication,
} from '~entities/application';
import type { ApplicationDetails } from '~entities/application';
import { selectedPosAtom } from '~entities/pos';

import { FormHeader } from '~shared/ui/form-header';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';

import Text from '@breeze-platform-ui/text';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const ApplicationAgreementSigningPage = () => {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const selectedPos = useAtomValue(selectedPosAtom);
  const { isLoading, isSuccess, isError, data } = useApplication();

  const handleApplicationSigned = () => {
    const applicationData = queryClient.getQueryData<ApplicationDetails>(
      applicationsKeys.application(id)
    );
    if (applicationData) {
      const updatedApplication: ApplicationDetails = {
        ...applicationData,
        offerSigned: true,
        downPaymentVerified: !shouldVerifyDownPayment(applicationData),
      };
      const applicationsList = queryClient.getQueryData<ApplicationDetails[]>(
        applicationsKeys.listByPos(selectedPos?.id)
      );
      if (applicationsList?.length) {
        queryClient.setQueryData<ApplicationDetails[]>(
          applicationsKeys.listByPos(selectedPos?.id),
          applicationsList.map((application) =>
            application.id === id ? updatedApplication : application
          )
        );
      }
      queryClient.setQueryData<ApplicationDetails>(
        applicationsKeys.application(id),
        updatedApplication
      );
    }

    setTimeout(() => navigate(`/applications/${id}`, { replace: true }), 500);
  };

  return (
    <Screen
      header={
        <FormHeader
          href={`/applications/${id}/`}
          text="Enter the&nbsp;code to&nbsp;sign the&nbsp;agreement"
        />
      }
    >
      <ScrollIntoView>
        {isLoading && <Loader />}
        {isError && (
          <Text size={15} align="center">
            Couldn’t load the&nbsp;application info
          </Text>
        )}
        {isSuccess && (
          <ApplicationAgreementSingning
            applicationId={id}
            phoneNumber={data.person.mobilePhone}
            onSuccess={handleApplicationSigned}
          />
        )}
      </ScrollIntoView>
    </Screen>
  );
};
