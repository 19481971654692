import { useAgentsNewsItem } from './get-agent-news-item';
import {
  useAgentsNewsList,
  useAgentsNewsListWithUnreadCount,
} from './get-agent-news-list';
import { useBlockingAgentNews } from './get-blocking-agent-news';
import { useSurvey } from './get-survey';
import { useReadAgentNews } from './read-agent-news';
import { useUpdateSurveyData } from './update-survey-data';

export * from './api-types';

export const agentNewsApi = {
  useUpdateSurveyData,
  useReadAgentNews,
  useSurvey,
  useAgentsNewsItem,
  useBlockingAgentNews,
  useAgentsNewsList,
  useAgentsNewsListWithUnreadCount,
};
