import { useDi } from '~app/providers/di-provider';

import { Enabled, useQuery } from '@tanstack/react-query';

import { vasKeys } from './vas-keys';

import { AvailableVasList } from '../vas';

export const useAvailableVasList = (
  applicationId: string,
  options?: {
    enabled: Enabled<AvailableVasList, Error>;
  }
) => {
  const { vasApi } = useDi();

  return useQuery({
    queryKey: vasKeys.available(applicationId),
    queryFn: () => vasApi.getAvailableVasList({ applicationId }),
    ...options,
  });
};

export const useApplicationVasList = (applicationId: string) => {
  const { vasApi } = useDi();

  return useQuery({
    queryKey: vasKeys.list(applicationId),
    queryFn: () => vasApi.getApplicationVasList(applicationId),
  });
};
