import { useDi } from '~app/providers/di-provider';

import { useQuery } from '@tanstack/react-query';

import { agentIncentiveKeys } from './agent-incentive-keys';

export const useAgentIncentive = () => {
  const { agentIncentiveApi } = useDi();

  return useQuery({
    queryKey: agentIncentiveKeys.progress(),
    queryFn: () => agentIncentiveApi.getAgentIncentive(),
  });
};

export const useAgentIncentiveHistory = () => {
  const { agentIncentiveApi } = useDi();

  return useQuery({
    queryKey: agentIncentiveKeys.history(),
    queryFn: () => agentIncentiveApi.getAgentIncentiveHistory(),
  });
};
