import { OrderItemCategory } from '~entities/order-item';

import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

export const getCategories = (params?: HandlerParams<OrderItemCategory[]>) =>
  http.post('*/merchant-portal/api/v1/public/categories/get-list', async () => {
    return HttpResponse.json(
      mockSuccessResponse<OrderItemCategory[]>(
        params?.payload ?? [
          {
            code: '111',
            displayName: 'Mobile and Tablets',
            displayOrder: 1,
            displayDetails:
              'Devices you can carry around with you: smartphones, keypad phones, flip-phones',
          },
          {
            code: '112',
            displayName: 'Wearable gadgets',
            displayOrder: 1,
            displayDetails:
              'Tech you can wear on your body: Heaphones / Earphones, smartwatches, fitness trackers, wireless earbuds',
          },
          {
            code: '113',
            displayName: 'TV, home video, audi',
            displayOrder: 1,
            displayDetails:
              'Entertainment tech for the home: TVs, Home theaters, receivers, amplifiers, stereo sets, karaoke, DVD / Blu-ray players, etc',
          },
        ]
      )
    );
  });

export const getCategoriesError = http.post(
  '*/merchant-portal/api/v1/public/categories/get-list',
  async () => {
    return HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    });
  }
);
