import { useDi } from '~app/providers/di-provider';

import { useQuery } from '@tanstack/react-query';

import { personKeys } from './person-keys';

export const usePerson = () => {
  const { personApi } = useDi();

  return useQuery({
    queryKey: personKeys.get(),
    queryFn: () => personApi.getPerson(),
    gcTime: 0,
  });
};
