import { mockSuccessResponse } from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

export const kycOcr = (params?: HandlerParams) =>
  http.post('*/kyc/api/v1/kyc/images/upload_ocr_image', async () => {
    return HttpResponse.json(
      mockSuccessResponse(
        params?.payload ?? {
          transactionId: '914f23e6-c1f2-4aef-9254-9de98b764deb',
        }
      )
    );
  });

export const ocrResult = (params?: HandlerParams<undefined, 'NoResult'>) =>
  http.post('*/kyc/api/v1/get_full_ocr_result', async () => {
    if (params?.scenario === 'NoResult') {
      return HttpResponse.json(
        mockSuccessResponse({
          status: 'ERROR',
          errorCode: 'OCR_NO_RESULT',
        })
      );
    }
    return HttpResponse.json(
      mockSuccessResponse({
        status: 'SUCCESS',
        OCRresult: {
          firstName: 'Ivan',
          middleName: 'Ivanovich',
          lastName: 'Ivanov',
          idNumber: '0111-2659224-2',
          birthday: '1995/02/13',
        },
      })
    );
  });
