import {
  mockedErrorResponses,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { AttendanceStatus } from '../../attendance';
import { AttendanceLogPayload } from '../attendance-api';

export const getAttendanceLog = (
  params?: HandlerParams<Partial<AttendanceLogPayload>>
) =>
  http.post('*/attendance/api/v1/attendance/log', async () => {
    return HttpResponse.json(
      mockSuccessResponse<Partial<AttendanceLogPayload>>(
        params?.payload ?? {
          type: AttendanceStatus.CheckIn,
          posId: '123456',
        }
      )
    );
  });

export const getAttendanceLogError = http.post(
  '*/attendance/api/v1/attendance/log',
  async () => {
    return HttpResponse.json(mockedErrorResponses.INTERNAL_SERVER_ERROR, {
      status: 500,
    });
  }
);
