import { type PropsWithChildren, useEffect } from 'react';

import { AppLoadingPage } from '~widgets/app-loading-page';

import {
  isAuthorizedAtom,
  updateUserInfoAtom,
  isSessionRestoredAtom,
  useValidate,
  useRestoredUserInfo,
} from '~entities/auth';

import { useAtom, useSetAtom } from 'jotai';

export const SessionInitializerWrapper = ({ children }: PropsWithChildren) => {
  const [isSessionRestored, setSessionRestored] = useAtom(
    isSessionRestoredAtom
  );
  const validateStatus = useValidate({ enabled: !isSessionRestored });
  const userInfoStatus = useRestoredUserInfo({
    enabled: !isSessionRestored,
  });

  const setIsAuthorized = useSetAtom(isAuthorizedAtom);
  const setUserInfo = useSetAtom(updateUserInfoAtom);

  useEffect(() => {
    if (
      !isSessionRestored &&
      !validateStatus.isLoading &&
      !userInfoStatus.isLoading
    ) {
      setIsAuthorized(validateStatus.isSuccess);
      setUserInfo(userInfoStatus.data || null);
      setSessionRestored(true);
    }
  }, [
    validateStatus,
    userInfoStatus,
    setIsAuthorized,
    setUserInfo,
    isSessionRestored,
    setSessionRestored,
  ]);

  if (!isSessionRestored) {
    return <AppLoadingPage />;
  }
  return <>{children}</>;
};
