import { environment } from '~app/environment';

import { type IdDocumentType } from '~entities/person';
import { idDocumentTypeNames } from '~entities/person/constants/id-document-type-names';

import type { RadioOption } from '~shared/types/radio-option';

export type MainIdDocumentTypeValue = Extract<
  IdDocumentType,
  'UMID' | 'PRC' | 'SSS'
>;
export type OtherIdDocumentTypeValue = Extract<
  IdDocumentType,
  | 'PHIL_ID'
  | 'DRIVING_LICENSE'
  | 'PASSPORT'
  | 'POSTAL_ID'
  | 'PHIL_SYS_ID'
  | 'DIGITAL_NATIONAL_PHIL_ID'
  | 'E_PHIL_ID'
>;

export const idDocumentTypeMainOptions = [
  {
    label: idDocumentTypeNames.UMID,
    value: 'UMID',
  },
  {
    label: idDocumentTypeNames.SSS,
    value: 'SSS',
  },
  {
    label: idDocumentTypeNames.PRC,
    value: 'PRC',
  },
] as RadioOption<MainIdDocumentTypeValue>[];

const showPhilIdSubtypes = environment.PHIL_ID_SUBTYPES === 'ON';

const philIdSubtypes = [
  {
    label: idDocumentTypeNames.PHIL_SYS_ID,
    value: 'PHIL_SYS_ID',
  },
  {
    label: idDocumentTypeNames.E_PHIL_ID,
    value: 'E_PHIL_ID',
  },
  {
    label: idDocumentTypeNames.DIGITAL_NATIONAL_PHIL_ID,
    value: 'DIGITAL_NATIONAL_PHIL_ID',
  },
];

const philId = [
  {
    label: idDocumentTypeNames.PHIL_ID,
    value: 'PHIL_ID',
  },
];

export const idDocumentTypeOtherOptions = [
  {
    label: idDocumentTypeNames.PASSPORT,
    value: 'PASSPORT',
  },
  {
    label: idDocumentTypeNames.DRIVING_LICENSE,
    value: 'DRIVING_LICENSE',
  },
  ...(showPhilIdSubtypes ? philIdSubtypes : philId),
  {
    label: idDocumentTypeNames.POSTAL_ID,
    value: 'POSTAL_ID',
  },
] as RadioOption<OtherIdDocumentTypeValue>[];
