import { delay, http, HttpResponse } from 'msw';

import agreementUrl from './assets/example-agreement.pdf';

export const getDocument = () =>
  http.post(
    '*/person-documents/api/v1/portal/loan-agreement/get-file',
    async () => {
      const buffer = await fetch(agreementUrl).then((response) => {
        return response.arrayBuffer();
      });

      return HttpResponse.arrayBuffer(buffer, {
        headers: {
          'Content-Type': 'application/pdf',
        },
      });
    }
  );

export const getDocumentError = () =>
  http.post(
    '*/person-documents/api/v1/portal/loan-agreement/get-file',
    async () => {
      await delay(2000);
      return new HttpResponse(null, {
        status: 401,
        statusText: 'Unauthorized',
      });
    }
  );
