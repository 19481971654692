import { UserInfo } from '~entities/auth/auth';

import { mockSuccessResponse } from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

export const getUserInfo = (params?: HandlerParams<Partial<UserInfo>>) =>
  http.post('*/auth/api/v1/merchant_authorizer/users/userinfo', async () => {
    return HttpResponse.json(
      mockSuccessResponse({
        employeeId: '5e5facde-7d90-465b-b80e-2ddc981beb08',
        employeeRole: 'AGENT',
        ...(params?.payload ?? {}),
      })
    );
  });
