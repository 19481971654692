export const applicationStepNames = {
  clientAssessment: 'clientAssessment',
  ephilVerificationLink: 'ephilVerificationLink',
  ephilVerificationForm: 'ephilVerificationForm',
} as const;

export const personDetailsStepNames = {
  idDocumentType: 'idDocumentType',
  idDocument: 'idDocument',
  additionalIdDocument: 'additionalIdDocument',
  clientPhoto: 'clientPhoto',
  gCashDetails: 'gCashDetails',
} as const;

export const stepNames = {
  ...applicationStepNames,
  ...personDetailsStepNames,
} as const;

export type ApplicationStepName =
  (typeof applicationStepNames)[keyof typeof applicationStepNames];

export type PersonDetailsStepName =
  (typeof personDetailsStepNames)[keyof typeof personDetailsStepNames];

export type StepName = (typeof stepNames)[keyof typeof stepNames];
