import { Address } from '~entities/address';

import { mockSuccessResponse } from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

type Payload = {
  items: Address[];
};

export const addressSuggestionPayload = [
  {
    region: 'South Luzon',
    province: 'Palawan',
    cityOrMunicipality: 'Aborlan',
  },
  {
    region: 'South Luzon',
    province: 'Occidental Mindoro',
    cityOrMunicipality: 'Abra De Ilog',
  },
  {
    region: 'North Luzon',
    province: 'Bataan',
    cityOrMunicipality: 'Abucay',
  },
  {
    region: 'North Luzon',
    province: 'Cagayan',
    cityOrMunicipality: 'Abulug',
  },
  {
    region: 'Visayas',
    province: 'Leyte',
    cityOrMunicipality: 'Abuyog',
  },
  {
    region: 'North Luzon',
    province: 'Nueva Vizcaya',
    cityOrMunicipality: 'Dupax Del Sur',
    barangay: 'Abaca',
  },
  {
    region: 'Visayas',
    province: 'Iloilo',
    cityOrMunicipality: 'San Enrique',
    barangay: 'Acacia',
  },
  {
    region: 'Metro Manila',
    province: 'Metro Manila',
    cityOrMunicipality: 'Sampaloc',
    barangay: 'Barangay 395',
  },
];

export const getAddressSuggestions = (
  params?: HandlerParams<Payload, 'Dynamic'>
) =>
  http.post(
    '*/delivery/api/v1/delivery/address/suggestion',
    async ({ request }) => {
      if (params?.scenario === 'Dynamic') {
        const reqParams = (await request.json()) as Record<string, string>;

        return HttpResponse.json(
          mockSuccessResponse({
            items:
              reqParams?.barangay &&
              reqParams?.cityOrMunicipality &&
              reqParams?.province
                ? []
                : [addressSuggestionPayload[6]],
          })
        );
      }
      return HttpResponse.json(
        mockSuccessResponse(
          params?.payload ?? {
            items: addressSuggestionPayload,
          }
        )
      );
    }
  );
