import { getBalikGiftPayment, giftPaymentFromRate } from './get-gift-payment';
import { calculateMonthlyPayment } from './loan-math';

import type {
  ProductParams,
  PaymentPlan,
  ApprovedProductOption,
  ApprovedPaymentPlan,
} from '../product';

export function getPaymentPlans(
  loanAmount: number,
  {
    minMonthlyInterestRate,
    maxMonthlyInterestRate,
    minOriginationFeeFlat,
    maxOriginationFeeFlat,
    minOriginationFeeRate,
    maxOriginationFeeRate,
    termsInMonths,
  }: ProductParams,
  giftPayment?: {
    giftPaymentRatesByTerm?: Record<number, number>;
    calculateGiftCashbackWithoutRate?: boolean;
  }
): PaymentPlan[] {
  const { calculateGiftCashbackWithoutRate, giftPaymentRatesByTerm } =
    giftPayment ?? {};

  return termsInMonths.map((term) => {
    const minMonthlyPayment = calculateMonthlyPayment(
      loanAmount,
      +term,
      minMonthlyInterestRate,
      minOriginationFeeRate,
      minOriginationFeeFlat
    );
    const maxMonthlyPayment = calculateMonthlyPayment(
      loanAmount,
      +term,
      maxMonthlyInterestRate,
      maxOriginationFeeRate,
      maxOriginationFeeFlat
    );

    const giftPaymentRate = giftPaymentRatesByTerm?.[term];
    const giftPayment: PaymentPlan['giftPayment'] =
      calculateGiftCashbackWithoutRate
        ? getBalikGiftPayment({
            loanAmount,
            originationFeeFlat: minOriginationFeeFlat,
            monthlyInterestRate: minMonthlyInterestRate,
            originationFeeRate: minOriginationFeeRate,
            term,
          })
        : giftPaymentFromRate({ giftPaymentRate, loanAmount });

    return {
      term,
      minMonthlyPayment,
      maxMonthlyPayment,
      giftPayment,
    };
  });
}

export function getApprovedPaymentPlans(
  loanAmount: number,
  options: ApprovedProductOption[],
  giftPayment?: {
    giftPaymentRatesByTerm?: Record<number, number>;
    calculateGiftCashbackWithoutRate?: boolean;
  }
): ApprovedPaymentPlan[] {
  const { calculateGiftCashbackWithoutRate, giftPaymentRatesByTerm } =
    giftPayment ?? {};

  return options.map(
    ({
      id,
      loanTermInMonths,
      monthlyInterestRate,
      originationFeeFlat,
      originationFeeRate,
    }) => {
      const giftPaymentRate = giftPaymentRatesByTerm?.[loanTermInMonths];
      const giftPayment: PaymentPlan['giftPayment'] =
        calculateGiftCashbackWithoutRate
          ? getBalikGiftPayment({
              loanAmount,
              originationFeeFlat,
              monthlyInterestRate,
              originationFeeRate,
              term: loanTermInMonths,
            })
          : giftPaymentFromRate({ giftPaymentRate, loanAmount });

      return {
        approvedProductOptionId: id,
        term: loanTermInMonths,
        monthlyPayment: calculateMonthlyPayment(
          loanAmount,
          loanTermInMonths,
          monthlyInterestRate,
          originationFeeRate,
          originationFeeFlat
        ),
        giftPayment,
      };
    }
  );
}
