import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { validators } from '~shared/validators';

import omit from '@tinkoff/utils/object/omit';

import { AddressSuggestionItem } from './address-suggestion-item';

import type { ExtendedAddressSuggestionOption, SuggestionField } from '../api';
import { PROVINCE_LABEL, CITY_LABEL, BARANGAY_LABEL } from '../constants';
import { getChildFields, getInitialAddressValues } from '../lib';

type Props = {
  fieldNames: Record<string, string>;
  disabled?: boolean;
};

export const AddressSuggestion = ({ fieldNames, disabled }: Props) => {
  const { register, setValue, getValues } = useFormContext();

  const { province, cityOrMunicipality, barangay } = fieldNames;
  const fieldNamesMap: Record<string, SuggestionField> = {
    [province]: 'province',
    [barangay]: 'barangay',
    [cityOrMunicipality]: 'cityOrMunicipality',
  };

  const [validAddressValues, setValidAddressValues] = useState<{
    [field: string]: string;
  }>(() => getInitialAddressValues(getValues(), fieldNamesMap));

  const onOptionSelect =
    (name: string) =>
    (
      option: ExtendedAddressSuggestionOption,
      shouldResetChildFields: boolean
    ) => {
      const { postalCode, region } = option.origin;
      const currentValue = option.origin[fieldNamesMap[name]] as string;
      if (postalCode) {
        setValue(fieldNames.postalCode, postalCode);
      }
      if (region) {
        setValue(fieldNames.region, region);
      }
      if (shouldResetChildFields) {
        const childFields = getChildFields(name, [
          province,
          cityOrMunicipality,
          barangay,
        ]);
        // According to doc we can not set value to undefined
        // https://react-hook-form.com/docs/useform/setvalue

        childFields.forEach((field) => setValue(field, ''));
        setValidAddressValues((prev) => ({
          ...omit(childFields, prev),
          [name]: currentValue,
        }));
      } else {
        setValidAddressValues((prev) => ({ ...prev, [name]: currentValue }));
      }
    };

  const onFieldDisregard = (name: string) => {
    setValidAddressValues((prev) => omit([name], prev));
  };

  return (
    <>
      {/* shouldUnregister works properly and clears all fields values only if hidden fields are present in component's JSX */}
      <input
        hidden
        {...register(fieldNames.postalCode, { shouldUnregister: true })}
      />
      <input
        hidden
        {...register(fieldNames.region, { shouldUnregister: true })}
      />
      <input
        hidden
        {...register(fieldNames.country, {
          value: 'PHL',
          shouldUnregister: true,
        })}
      />
      <AddressSuggestionItem
        label={PROVINCE_LABEL}
        name={fieldNames.province}
        addressValues={validAddressValues}
        fieldNamesMap={fieldNamesMap}
        disabled={disabled}
        onOptionSelect={onOptionSelect(fieldNames.province)}
        onFieldDisregard={onFieldDisregard}
        rules={{
          validate: {
            required: validators.required({
              text: 'Enter or select customer’s province',
            }),
            suggestOption: (v) => {
              return v.value
                ? undefined
                : 'Select an option from the list or check the input';
            },
          },
        }}
      />
      <AddressSuggestionItem
        label={CITY_LABEL}
        name={fieldNames.cityOrMunicipality}
        addressValues={validAddressValues}
        fieldNamesMap={fieldNamesMap}
        disabled={disabled}
        onOptionSelect={onOptionSelect(fieldNames.cityOrMunicipality)}
        onFieldDisregard={onFieldDisregard}
        rules={{
          validate: {
            required: validators.required({
              text: 'Enter or select customer’s city or municipality',
            }),
            suggestOption: (v) => {
              return v.value
                ? undefined
                : 'Select an option from the list or check the input';
            },
          },
        }}
      />
      <AddressSuggestionItem
        label={BARANGAY_LABEL}
        name={fieldNames.barangay}
        addressValues={validAddressValues}
        fieldNamesMap={fieldNamesMap}
        disabled={disabled}
        onFieldDisregard={onFieldDisregard}
        onOptionSelect={onOptionSelect(fieldNames.barangay)}
        rules={{
          validate: validators.required({
            text: 'Enter or select customer’s barangay',
          }),
        }}
      />
    </>
  );
};
