import { useEffect } from 'react';

import { PendingNumber } from '~features/pending-number';

import { useAgentInfo } from '~entities/agent';
import {
  useAgentIncentive,
  isIncentiveProgramAvailableAtom,
} from '~entities/agent-incentive';
import { agentNewsApi } from '~entities/agent-news';

import Badge from '@breeze-platform-ui/badge';
import { Col, Row } from '@breeze-platform-ui/layout';
import {
  TuiIconTdsMediumList,
  TuiIconTdsMediumUserImpersonal,
  TuiIconTdsMediumSpeaker,
} from '@tui-react/proprietary-icons';
import { useSetAtom } from 'jotai';

import { NavigationButton } from './navigation-button';
import styles from './navigation-footer.module.css';

const buttonContainerStyle = { flex: 1, flexBasis: '75px' };

export const NavigationFooter = () => {
  // Request in the background to know if incentive program is available
  const { data } = useAgentIncentive();
  const { data: agentNews } = agentNewsApi.useAgentsNewsListWithUnreadCount();
  const agentTypeQuery = useAgentInfo({
    select: (info) => info.employmentType,
  });

  const setIncentiveAvailable = useSetAtom(isIncentiveProgramAvailableAtom);

  const isIncentiveAvailable = data && data.steps.length > 0;

  useEffect(() => {
    if (isIncentiveAvailable) {
      setIncentiveAvailable(true);
    } else {
      setIncentiveAvailable(false);
    }
  }, [isIncentiveAvailable, setIncentiveAvailable]);

  return (
    <Row width="100%">
      <Col alignCross="center" style={buttonContainerStyle}>
        <NavigationButton
          route="/account"
          Icon={TuiIconTdsMediumUserImpersonal}
          name="My account"
          isHome={agentTypeQuery.data === 'FULL_TIME'}
        />
      </Col>
      <Col alignCross="center" style={buttonContainerStyle}>
        <NavigationButton
          route="/applications"
          Icon={TuiIconTdsMediumList}
          name="Applications"
          notification={
            <div className={styles.pendingAlert}>
              <PendingNumber />
            </div>
          }
        />
      </Col>
      <Col alignCross="center" style={buttonContainerStyle}>
        <NavigationButton
          route="/agent-news"
          Icon={TuiIconTdsMediumSpeaker}
          name="News"
          notification={
            agentNews && agentNews.unreadCount > 0 ? (
              <div className={styles.newsAlert}>
                <Badge customBackground="#F52222" size="m" padding={8}>
                  {agentNews.unreadCount}
                </Badge>
              </div>
            ) : (
              <></>
            )
          }
        />
      </Col>
    </Row>
  );
};
