import { useEffect } from 'react';

import { type Pos, PosItem, usePosList } from '~entities/pos';

import { Loader } from '~shared/ui/loader';

import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

type Props = {
  currentPos: Pos | null;
  onChange: (pos: Pos) => void;
};
export const SelectPos = ({ currentPos, onChange }: Props) => {
  const { data: items, isLoading } = usePosList();

  useEffect(() => {
    if (!currentPos && items && items.length > 0) {
      onChange(items[0]);
    }
  }, [items, currentPos, onChange]);

  const handlePosSelect = (pos: Pos) => {
    onChange(pos);
  };

  if (isLoading || !currentPos) {
    return <Loader />;
  }

  if (items?.length) {
    return (
      <Col gaps={24} alignCross="stretch">
        {items.map((pos) => (
          <PosItem
            key={pos.id}
            pos={pos}
            selected={currentPos?.id === pos.id}
            onSelect={() => handlePosSelect(pos)}
          />
        ))}
      </Col>
    );
  }

  // TODO: replace with error screen(needs design)
  return (
    <Text size={15} align="center">
      Couldn’t load the&nbsp;stores. Contact your ASM for assistance.
    </Text>
  );
};
