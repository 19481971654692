import { mockSuccessResponse } from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

import { AuthSuccessResponse } from '../auth-api';
import { GatewayOtpResponse } from '../gateway/gateway-auth-api';

export const getChanllengeFlowRespond = (
  params?: HandlerParams<GatewayOtpResponse | AuthSuccessResponse, 'Unsuccess'>
) =>
  http.post(
    '*/auth/api/v1/merchant_authorizer/challenge_flow/respond',
    async () => {
      if (params?.scenario === 'Unsuccess') {
        return HttpResponse.json(
          mockSuccessResponse({
            session:
              'AYABeBfEywpUOK4hI6Nhwk_i-J8AHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMAUGFybjphd3M6a21zOmFwLXNvdXRoZWFzdC0xOjAzMTU3NzI0MDA0ODprZXkvYmEwNzA1YzktMTI0Mi00ODg1LWJhMmYtNDhiMWNjYTNiNDNmALgBAgEAePlZjnuzMigeBhd7j3r5BNX7R8efbv8cmJ1p4sdawJBQAfKcJQejF2SwaIruGXBbmG4AAAB-MHwGCSqGSIb3DQEHBqBvMG0CAQAwaAYJKoZIhvcNAQcBMB4GCWCGSAFlAwQBLjARBAzb1_nlf5E0VaYvJwoCARCAO4ypdtqCmmMMVvbwlAxcTiV0Zfud6A6J2IS7nlJa4jf5L8IaopWRozwdv43VLcrfEQyE5Zcus5ipEQRKAgAAAAAMAAAQAAAAAAAAAAAAAAAAAArnwu2B-ulYuLDOj215Zcb_____AAAAAQAAAAAAAAAAAAAAAQAAAbgiRpzEEKvZEQnIWV3jN287XTCDrwtlSiu6flny79A7pnhpsAww5JW3bXsqhwNAthob1BX0NcQMdeXmCeAFIxeq-FJHJfyNOTg2OUYiip-uCRv7KRNguREXtTxzLK7B1giKLule6KIslY-8ul9vp5GzCfAVJo_JRa0Qjg8EDsou6XMOkx61L18D7NLGgfIINBXpM38DsL0X0fuk4VxnT3VTmE5hXAyNuBacs_ZNRBHB9zFGL2pWnJlPq04mO8RnHG1wGQGFnt9zWnoD0kwbJFYhWJmQ_qILB8g9WihmLADHP2aycB60wRJFmX7Ukp2J8wIYMCxcjeqPEB11hnz2W7PM9jVzqlSQOAlIqoyRfCojhSmpg657qjS0n8xowdti90_weI6L7pkmISq4VfnI6Un90wjRRznoRyxdBintkEBu9gdbT633umCR78bWWdSg__EXIzfBLv8CvWPyaXumJh9x3ayNnnhnCbLNHNrIAaE5yoIIeoz_AYARgUTYiPzsG0jSxsO4A8h2p2rz9v3VPlKiIQ0A8Y6s4Rdbkd1qB9QjUX9sipt97wb77u_1jdh9sw8z11P1Ioyl_zMRR5f69YQplB4Aep5ILqg',
            challengeKind: 'OTP',
            challengeParameters: {
              username: 'fc620419-f308-4fa7-bf2e-eed7979df70d',
              phoneNumber: '+6392****1114',
              delaySec: 30,
              expiresAt: '2025-03-11T13:30:44Z',
              isNew: false,
              remainingOtpEnterAttempts: 4,
            },
          })
        );
      }
      return HttpResponse.json(mockSuccessResponse(params?.payload ?? {}));
    }
  );
