import { useDi } from '~app/providers/di-provider';

import { Enabled, useQuery } from '@tanstack/react-query';

import { documentKeys } from './document-keys';

export const useGenerateAgreementQuery = (options?: {
  enabled: Enabled<'OK', Error, 'OK'>;
}) => {
  const { agreementGenerationApi } = useDi();
  return useQuery({
    queryKey: documentKeys.generateAgreement(),
    queryFn: () => agreementGenerationApi.generate(),
    /**
     * The agreement does not have a unique identifier so
     * we do not cache it at all because it needs to be unique
     * for each new application and loan parameters.
     */
    gcTime: 0,
    ...options,
  });
};
