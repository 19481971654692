import { calculateGiftPayment } from '~entities/gift-payment/lib/calculate-gift-payment';

import { calculateLoanWithFee, calculateMonthlyPayment } from './loan-math';

export const giftPaymentFromRate = ({
  giftPaymentRate,
  loanAmount,
}: {
  giftPaymentRate?: number;
  loanAmount: number;
}) =>
  giftPaymentRate
    ? {
        ratePercent: giftPaymentRate,
        amount: calculateGiftPayment({ rate: giftPaymentRate, loanAmount }),
      }
    : undefined;

export const getBalikGiftPayment = ({
  loanAmount,
  term = 0,
  originationFeeFlat = 0,
  monthlyInterestRate = 0,
  originationFeeRate = 0,
}: {
  loanAmount: number;
  term: number;
  originationFeeFlat: number;
  monthlyInterestRate: number;
  originationFeeRate: number;
}) => ({
  amount: Math.floor(
    calculateMonthlyPayment(
      loanAmount,
      term,
      monthlyInterestRate,
      originationFeeRate,
      originationFeeFlat
    ) *
      term -
      calculateLoanWithFee(loanAmount, originationFeeRate, originationFeeFlat)
  ),
});
