import { isHttpError } from '@tinkoff/request-plugin-protocol-http';

import { Enabled, useQuery } from '@tanstack/react-query';

import { AttendanceStatusResponse } from './attendance-api';
import { attendanceKeys } from './attendance-keys';
import { gatewayAttendanceApi } from './gateway-attendance-api';

export const useAttendanceStatus = (options?: {
  enabled: Enabled<AttendanceStatusResponse, Error, AttendanceStatusResponse>;
}) => {
  return useQuery({
    queryKey: attendanceKeys.status(),
    queryFn: async () => gatewayAttendanceApi.getAttendanceStatus(),
    refetchInterval: 5 * 60 * 1000,
    retry: (count, error) => {
      if (isHttpError(error) && error.status === 429) {
        return false;
      }

      return count < 3;
    },
    ...options,
  });
};
