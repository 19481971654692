import { useDi } from '~app/providers/di-provider';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { CancelApplicationPayload } from './application-api';

export const useCancelApplication = (
  options?: UseMutationOptions<unknown, Error, CancelApplicationPayload>
) => {
  const { applicationApi } = useDi();

  return useMutation({
    mutationFn: (payload: CancelApplicationPayload) =>
      applicationApi.cancelApplication(payload),
    ...options,
  });
};
