import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { OfferBanner, useSearchOfferByPhone } from '~entities/offer';
import type { SearchOfferResponse, Offer } from '~entities/offer';

import { nonNullableValue } from '~shared/types/non-nullable-value';

import { OfferDetailsForm } from '../offer-details-form/offer-details-form';

export type ManageOfferValues = {
  offer?: SearchOfferResponse | null;
  offerPhone: string;
};

export type ManageOfferFormValue = {
  offerPhone?: string;
  promoCode?: string;
};

export type ActiveOffer = {
  offer: Offer;
  phone: string;
};

export const ManageOfferByPhone = ({
  activeOffer,
  onUpdateOffer,
  onCancelOffer,
  onSearchOfferError,
}: {
  activeOffer?: ActiveOffer;
  onUpdateOffer: (activeOffer: ActiveOffer) => void;
  onCancelOffer: () => void;
  onSearchOfferError: () => void;
}) => {
  const methods = useForm<ManageOfferFormValue>({
    defaultValues: { offerPhone: activeOffer?.phone },
    mode: 'onBlur',
  });
  const { getValues, setValue } = methods;

  useEffect(() => {
    if (activeOffer?.phone !== getValues('offerPhone')) {
      setValue('offerPhone', activeOffer?.phone);
    }
  }, [activeOffer, getValues, setValue]);

  const { data, isError, isPending, mutate } = useSearchOfferByPhone();

  const isSearchOfferError = isError || data?.status === 'NOT_FOUND';

  useEffect(() => {
    if (data?.status === 'SUCCESS') {
      onUpdateOffer({
        offer: data,
        phone: nonNullableValue(getValues().offerPhone),
      });
    }
  }, [data, onUpdateOffer, getValues]);

  useEffect(() => {
    if (isSearchOfferError) {
      onSearchOfferError();
    }
  }, [isSearchOfferError, onSearchOfferError]);

  const handleCancelOffer = () => {
    methods.reset({ offerPhone: '' });
    onCancelOffer();
  };

  return (
    <FormProvider {...methods}>
      {activeOffer ? (
        <OfferBanner
          {...activeOffer.offer.posBannerTemplateParams}
          onCancel={handleCancelOffer}
        />
      ) : (
        <OfferDetailsForm
          isLoading={isPending}
          isError={isSearchOfferError}
          onSubmitOfferPhone={(phone) => phone && mutate(phone)}
        />
      )}
    </FormProvider>
  );
};
