import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { gatewayMarketingLeadsApi } from './marketing-leads-api';
import { leadsKeys } from './marketing-leads-keys';

import type { MarketingLead } from '../marketing-lead';

export function useMarketingLeads<TSelect = MarketingLead[]>(options?: {
  select?: (value: MarketingLead[]) => TSelect;
}) {
  return useQuery({
    queryKey: leadsKeys.list(),
    queryFn: () => gatewayMarketingLeadsApi.getLeads(),
    placeholderData: keepPreviousData,
    ...options,
  });
}
