import { formatClientAssessment } from '~widgets/client-assessment';
import { formatAdditionalIdDocument } from '~widgets/loan-application-form/additional-id-document';
import { formatAlternativeContacts } from '~widgets/loan-application-form/alternative-contacts';
import { formatClientPhoneAssessment } from '~widgets/loan-application-form/client-phone-assessment';
import { formatEphilVerification } from '~widgets/loan-application-form/e-phil-verification/lib/formatter';
import { formatEmploymentDetails } from '~widgets/loan-application-form/employment';
import { formatAdditionalEmploymentDetails } from '~widgets/loan-application-form/employment';
import { formatFinances } from '~widgets/loan-application-form/finances';
import { formatGCashDetails } from '~widgets/loan-application-form/gcash-details';
import { formatIdDocument } from '~widgets/loan-application-form/id-document';
import { formatIdDocumentType } from '~widgets/loan-application-form/id-document-type';
import { formatPermanentAddress } from '~widgets/loan-application-form/permanent-address';
import { formatPersonalDetails } from '~widgets/loan-application-form/personal-details';
import { formatResidentialAddress } from '~widgets/loan-application-form/residential-address';

import type { Application, ApplicationFormatter } from '~entities/application';
import type { Person, PersonFormatter } from '~entities/person';

import { getValidValues } from '~shared/lib/get-valid-values';

import identity from '@tinkoff/utils/function/identity';
import isEmpty from '@tinkoff/utils/is/empty';

import type { FormErrors } from './form-values';
import type {
  PersonDetailsStepName,
  ApplicationStepName,
  StepName,
} from './step-names';
import { personDetailsStepNames, applicationStepNames } from './step-names';

export const personScreenFormatters: Record<
  PersonDetailsStepName,
  PersonFormatter<any>
> = {
  [personDetailsStepNames.personalDetails]: formatPersonalDetails,
  [personDetailsStepNames.residentialAddress]: formatResidentialAddress,
  [personDetailsStepNames.permanentAddress]: formatPermanentAddress,
  [personDetailsStepNames.employmentDetails]: formatEmploymentDetails,
  [personDetailsStepNames.additionalEmploymentDetails]:
    formatAdditionalEmploymentDetails,
  [personDetailsStepNames.finances]: formatFinances,
  [personDetailsStepNames.alternativeContacts]: formatAlternativeContacts,
  [personDetailsStepNames.idDocumentType]: formatIdDocumentType,
  [personDetailsStepNames.idDocument]: formatIdDocument,
  [personDetailsStepNames.additionalIdDocument]: formatAdditionalIdDocument,
  [personDetailsStepNames.clientPhoto]: identity,
  [personDetailsStepNames.gCashDetails]: formatGCashDetails,
};

export const applicationScreenFormatters: Record<
  ApplicationStepName,
  ApplicationFormatter<any>
> = {
  [applicationStepNames.clientPhoneAssessment]: formatClientPhoneAssessment,
  [applicationStepNames.clientAssessment]: formatClientAssessment,
  [applicationStepNames.ephilVerificationLink]: identity,
  [applicationStepNames.ephilVerificationForm]: formatEphilVerification,
};

type ScreensValues = Record<StepName, any>;

export const formatPersonScreenValues = (
  names: PersonDetailsStepName[],
  values: Partial<ScreensValues>,
  errors: Partial<FormErrors>
): Person => {
  let person = {} as Person;

  for (const screenName of names) {
    const screenValue = values[screenName] ?? {};
    const validScreenValue = getValidValues(
      screenValue,
      errors[screenName] || ({} as any)
    );

    person = personScreenFormatters[screenName](
      person,
      isEmpty(validScreenValue) ? {} : validScreenValue
    );
  }

  return person;
};

export const formatApplicationScreenValues = (
  names: ApplicationStepName[],
  values: Partial<ScreensValues>,
  errors: Partial<FormErrors>
): Application => {
  let application = {
    formFillingStatus: 'IN_PROGRESS',
    requestedProduct: { type: 'POS_LOAN' },
  } as Application;

  for (const screenName of names) {
    const screenValue = values[screenName] ?? {};
    const validScreenValue = getValidValues(
      screenValue,
      errors[screenName] ?? ({} as any)
    );

    application = applicationScreenFormatters[screenName](
      application,
      isEmpty(validScreenValue) ? {} : validScreenValue
    );
  }

  return application;
};
