import type { FileItem } from '../file-item';

export type DocType =
  | 'UMID'
  | 'SSS'
  | 'PASSPORT'
  | 'DRIVING_LICENSE'
  | 'PHIL_ID'
  | 'PRC'
  | 'CLIENT_PORTRAIT'
  | 'POSTAL_ID'
  | 'RECEIPT'
  | 'IMEI'
  | 'SERIAL_NUMBER'
  | 'GCASH_SCORE'
  | 'GCASH_PROFILE'
  | 'CLIENT_PORTRAIT_WITH_ITEM'
  | 'PHIL_SYS_ID'
  | 'DIGITAL_NATIONAL_PHIL_ID'
  | 'E_PHIL_ID';

export enum FileValidationErrorCode {
  UnsupportedImageType = 'UNSUPPORTED_IMAGE_TYPE',
  TooLargeImageSize = 'TOO_LARGE_IMAGE_SIZE',
  RequestEntityTooLarge = 'REQUEST_ENTITY_TOO_LARGE',
  LimitsResolution = 'IMAGE_RESOLUTION_OUT_OF_LIMITS',
}

export type FileUploadPayload = {
  applicationId: string;
  fileItem: FileItem;
};

export type FileUploadResponse = {
  transactionId: string;
};

export type CheckImageResponse = {
  status: 'ERROR' | 'SUCCESS' | 'PENDING';
  message: string;
};

export class PendingError extends Error {
  name = 'PENDING_ERROR';
}

export function isPendingError(error: Error): error is PendingError {
  return error.name === 'PENDING_ERROR';
}

export function isOcrErrorResponse(
  response: OcrResponse
): response is OcrErrorResponse {
  return response.status === 'ERROR';
}
export function isOcrNoResultErrorResponse(response: OcrResponse) {
  return response.status === 'ERROR' && response.errorCode === 'OCR_NO_RESULT';
}

export function isOcrDocTypeNotMatchErrorResponse(response: OcrResponse) {
  return (
    response.status === 'ERROR' && response.errorCode === 'CARD_TYPE_NOT_MATCH'
  );
}

export function isOcrSuccessResponse(
  response: OcrResponse
): response is OcrSuccessResponse {
  return response.status === 'SUCCESS';
}

export type OcrResult = {
  birthday?: string;
  civilStatus?: string;
  documentNumber?: string;
  idNumber?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  placeOfBirth?: string;
};

export type OcrSuccessResponse = {
  status: 'SUCCESS';
  OCRresult?: OcrResult;
};

export type OcrPendingResponse = {
  status: 'PENDING';
};

export type OcrErrorResponse = {
  status: 'ERROR';
  errorCode: 'OCR_NO_RESULT' | 'CARD_TYPE_NOT_MATCH';
};

export type OcrResponse =
  | OcrPendingResponse
  | OcrErrorResponse
  | OcrSuccessResponse;

export interface FileUploadApi {
  uploadFile: (payload: FileUploadPayload) => Promise<FileUploadResponse>;
  checkImage: (transactionId: string) => Promise<CheckImageResponse>;
  getFullOcrResult: (
    transactionId: string
  ) => Promise<OcrSuccessResponse | OcrErrorResponse>;
}
