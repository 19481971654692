export const environment = {
  // @ts-expect-error — get this variable from vite.config.ts
  VERSION: __APP_VERSION__,
  ENV: import.meta.env.VITE_ENV,
  AUTH_CLIENT_ID: import.meta.env.VITE_AUTH_CLIENT_ID,
  API_HOST: import.meta.env.VITE_API_HOST,
  SENTRY_HOST: import.meta.env.VITE_SENTRY_HOST,
  LOGS_COLLECTOR_HOST: import.meta.env.VITE_LOGS_COLLECTOR_HOST,
  AMPLITUDE_ANALYTICS_KEY: import.meta.env.VITE_AMPLITUDE_ANALYTICS_KEY,
  AMPLITUDE_ANALYTICS_BY_APPLICATION_KEY: import.meta.env
    .VITE_AMPLITUDE_ANALYTICS_BY_APPLICATION_KEY,
  AMPLITUDE_ANALYTICS_BY_CALC_KEY: import.meta.env
    .VITE_AMPLITUDE_ANALYTICS_BY_CALC_KEY,
  USER_INACTIVE_THRESHOLD: import.meta.env.VITE_USER_INACTIVE_THRESHOLD,
  POS_LOAN_APPROVAL_TIME_LIMIT: import.meta.env
    .VITE_POS_LOAN_APPROVAL_TIME_LIMIT,
  FILE_UPLOAD_API_HOST: import.meta.env.VITE_FILE_UPLOAD_API_HOST,
  APPLICATION_CANCEL_FEATURE: import.meta.env.VITE_APPLICATION_CANCEL_FEATURE,
  SKIP_VALIDATION_FEATURE: import.meta.env
    .VITE_SKIP_VALIDATION_FEATURE_TEST_ONLY,
  PROMOTER_CODE_FEATURE: import.meta.env.VITE_PROMOTER_CODE_FEATURE,
  CONFIRMATION_SERVICE_FEATURE: import.meta.env
    .VITE_CONFIRMATION_SERVICE_FEATURE,
  COLLECT_GCASH_DATA_FEATURE: import.meta.env.VITE_COLLECT_GCASH_DATA_FEATURE,
  CLIENT_PHOTO_WITH_ITEM_FEATURE: import.meta.env.VITE_CLIENT_PHOTO_WITH_ITEM,
  PHIL_ID_SUBTYPES: import.meta.env.VITE_PHIL_ID_SUBTYPES_FEATURE,
};
