import { Offer } from '~entities/offer';

import {
  mockErrorResponse,
  mockSuccessResponse,
} from '~shared/lib/mock-responses';

import { http, HttpResponse } from 'msw';
import { HandlerParams } from '~tests/types/mock-handler';

export const getOffer = (params?: HandlerParams<Offer, 'NotFound'>) =>
  http.post(
    '*/bros/marketing/api/v1/public/offers/pos/get-active-by-phone',
    async () => {
      if (params?.scenario === 'NotFound') {
        return HttpResponse.json(
          mockErrorResponse({
            code: 'NOT_FOUND',
            message: 'entity=[offer] was not found by phone=[+639923849203]',
          })
        );
      }

      return HttpResponse.json(
        mockSuccessResponse(
          params?.payload ?? {
            id: 'TEST_OFFER_ID',
            type: 'POS',
            productSegment: 'POS',
            posProductId: 'TEST_POS_PRODUCT_ID_WITH_OFFER',
            posBannerTemplateParams: {
              title: 'Super Offer',
              subtitle: 'Buy everything for free',
            },
          }
        )
      );
    }
  );
